/* whole page background */
.user-dashboard-container {
  background-color: var(--body_background);
}

/* greerting div */
.greeting-div {
  font-family: "Open-Sans-Light";
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: rgba(76, 78, 100, 0.55);
  margin-bottom: 20px;
}

.greeting-div h2 {
  font-family: "Open-Sans-semi-bold";
  font-size: 35px;
  font-weight: 800;
  color: rgba(76, 78, 100, 0.75);
}
/* issueType card container  */
.issue-type-row-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px; /* Gap between columns */
  margin-bottom: 10px;
}

.header-skeletons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px; 
  /* width: 600px; */
  /* min-width: 600px; */
  /* margin-bottom: 10px; */
}

/* all the continers css */
/* card-Div-issueType */
.card-Div-issueType {
  border-radius: 6px;
  min-height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px 0px gray;
  padding: 10px;
  margin: 4px !important;
  max-height: fit-content;
}
.card-Div-issueType:hover {
  box-shadow: 0px 0px 3px 0px gray;
}

/* Adjust accordion collapse animation duration */
.collapse {
  transition-duration: 1s; /* Adjust the duration (e.g., 0.5s for half a second) */
  transition-timing-function: ease-in-out; /* Use ease-in-out timing function */
}

/* containers icons & colors */
.user-dashboard-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.bg-red {
  background-color: rgba(240, 68, 56, 0.85);
}
.bg-green {
  background-color: rgb(16, 185, 129, 0.85);
}
.bg-blue {
  background-color: rgb(99, 102, 241, 0.85);
}
.bg-orange {
  background-color: rgb(247, 144, 9, 0.85);
}
.bg-sky-blue {
  background-color: rgb(6, 174, 212, 0.85);
}
.bg-gray {
  background-color: gray;
}
.bg-profile {
  background-color: rgb(102, 108, 255, 0.85);
}

/* card-div Heading Css */
.card-Div-heading {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.75);
  font-weight: 500;
  font-size: 1.25rem;
  text-wrap: nowrap;
}

/* resolved container css */

.resolved-div,
.pending-div {
  font-style: "Open-Sans-OpenSans-Condensed-Bold";
  padding: 8px 3px;
  padding-bottom: 5px;
  color: rgba(76, 78, 100, 0.87);
  border-top: 1px solid rgba(128, 128, 128, 0.137);
}

.resolved-div div,
.pending-div div {
  font-family: "Open-Sans-semi-bold";
  color: rgba(76, 78, 100, 0.85);
  font-size: 1rem;
  /* font-weight: 500; */
}

.resolved-div div span,
.pending-div div span {
  color: rgba(76, 78, 100, 0.6);
  font-size: 1.05rem;
  font-weight: 500;
}

.resolved-div p,
.pending-div p {
  text-align: end;
  font-size: 0.9rem;
  color: rgba(76, 78, 100, 0.5);
  font-weight: 400;
  margin-bottom: 0px;
  display: inline-block;
}

.resolved-heading-div span {
  padding-left: 5px;
}

/* Progress bar Divs */
.grid-container {
  /* list-style: none; Remove default list styles */
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.grid-item {
  display: grid;
  grid-template-columns: auto 50% 15% 25%; /* Define column widths */
  grid-template-rows: 25px;
  gap: 0px; /* Add gap between items */

  /* border: 1px solid #ccc; */
}

.grid-item div {
  /* margin-right: 10px; */
  text-align: center;
  align-self: center;
  /* background-color: blue; */
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 5px;
}
.grid-item span {
  /* text-emphasis: ; */
  /* align-self: ; */
  text-transform: capitalize;
}

.bg-progress-id-ongoing {
  background-color: rgb(38, 198, 249);
}
.bg-progress-id-resolved {
  background-color: rgb(131, 229, 66);
}
.bg-progress-id-validation {
  background-color: #a255ff;
}
.bg-progress-id-pending {
  background-color: rgb(255, 98, 95);
}

/* Pie Chart */

.pie-chart-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: auto;
}

/* Pie-chart grid */
.pie-chart-grid-container {
  /* list-style: none; Remove default list styles */
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.pie-chart-grid-item {
  display: grid;
  grid-template-columns: auto 35% 10% 10% 10% 10% 10% 10%; /* Define column widths */
  grid-template-rows: 25px;
  gap: 0px; /* Add gap between items */
  background-color: rgb(240, 239, 239);
  color: #4c4e64c7;
  font-family: "Open-Sans-semi-bold";
  font-weight: 700;
  font-size: 15px;
  border-radius: 5px;
  margin: 5px 0px;
  padding: 2px 0px;
}

.pie-chart-grid-item div {
  /* margin-right: 10px; */
  text-align: center;
  align-self: center;
  /* background-color: blue; */
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 5px;
}

.pie-chart-grid-item span {
  /* text-emphasis: ; */
  /* align-self: ; */
  text-transform: capitalize;
  text-align: center;
  align-self: center;
}

.subissue-chart-desc {
  text-align: left !important;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg-progress-id-ongoing {
  background-color: rgb(38, 198, 249);
}
.bg-progress-id-resolved {
  background-color: rgb(131, 229, 66);
}
.bg-progress-id-validation {
  background-color: #a255ff;
}
.bg-progress-id-pending {
  background-color: rgb(255, 98, 95);
}

/* pie-chart-label */

.pie-chart-label-grid-container {
  display: flex;
  list-style: none;
  flex-direction: column;
  background-color: rgb(240, 239, 239);
  padding: 5px 10px;
  padding-right: 0px;
  border-radius: 10px;
  color: rgba(76, 78, 100, 0.9);
  font-size: 0.7rem;
  font-weight: 600;
}
.pie-chart-label-grid-item {
  display: grid;
  grid-template-columns: 10% 90%;
  justify-content: center;
  align-items: center;
}

.pie-chart-label-grid-item div {
  text-align: center;
  align-self: center;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 20px;
}

.pie-chart-label-grid-item span {
  text-transform: capitalize;
  text-align: left;
  align-self: center;
  margin-left: 10px;
  display: inline-block;
}

.pie-chart-label-grid-container span:first-child {
  display: -webkit-box;
}

/* coustom barchart css */
.coustom_bar_chart {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  align-items: center;
  gap: 10px;
}

.coustom_bar_chart_y_axis {
  width: 100%;
  text-align: right;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  text-wrap: nowrap;
  text-transform: capitalize;
  font-size: 15px;
  font-family: "Open-Sans-Light";
  font-weight: 600;
  color: rgba(76, 78, 100, 0.78);
}
.coustom_bar_chart_bar_div {
  height: 40px;
  width: 100%;
  border-left: 1px solid #cde1ff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.coustom_bar_chart_bar {
  height: 15px; /* Set initial height */
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: inline-block;
  transition: height 0.3s, transform 0.3s; /* Add transition for smooth animation */
}
.coustom_bar_chart_bar_time_label {
  font-size: 14px;
  font-family: "Open-Sans-Light", sans-serif;
  font-weight: 700;
  color: rgba(76, 78, 100, 0.68);
}

.coustom_bar_chart_bar:hover {
  height: 40%; /* Expand width to 100% on hover */
  transform: scaleY(1.5); /* Scale height on hover */
}

/* Bar chart title*/

.avg-completion-time-label {
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: "Open-Sans-Light", sans-serif;
  font-weight: 600;
  color: rgba(76, 78, 100, 0.68);
}

/* count div heading  */
.ticket_heading_div {
  color: rgba(76, 78, 100, 0.65);
  font-family: "Open-Sans-Light";
  font-weight: 600;
  font-size: 0.9rem;
}

/* top-performer-div */
.top-performer-div {
  text-align: center;
  text-wrap: wrap;
  font-size: 15px;
}
.top-performer-div .heading {
  font-family: "Open-Sans-Light";
  color: rgba(76, 78, 100, 0.65);
  font-weight: 700;
  font-size: 0.9rem;
  text-wrap: nowrap;
}
.top-ticket-sources-label {
  min-height: 40px !important;
  text-align: center !important;
  font-family: "Open-Sans-Medium";
  font-weight: 400;
  font-size: 18px;
  color: rgba(76, 78, 100, 0.65);
  margin-top: 30px !important;
}

.top-ticket-sources-label-visit {
  min-height: 40px !important;
  text-align: center !important;
  font-family: "Open-Sans-Medium";
  font-weight: 400;
  font-size: 18px;
  color: rgba(76, 78, 100, 0.65);
}

/* Export icon  */
.export_all_data_btn {
  margin: 0px 5px;
  font-size: 24px;
}
.export_all_data_btn:hover {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .avg-compeletion-time {
    margin-top: 20px;
  }
  /* Tablet view: Two cards per row */
  .issue-type-row-div {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .pie-chart-grid-item {
    font-size: 13px;
  }
  .pie-chart-div {
    width: 100%;
  }
  .coustom_bar_chart {
    grid-template-columns: 1fr 1.2fr;
  }
}
@media only screen and (max-width: 576px) {
  .pie-chart-div.recharts-wrapper {
    margin: -40px;
    width: auto !important;
    height: 41vh !important;
  }
  .avg-compeletion-time {
    margin-top: 5px;
    width: 100%;
  }
  .avg-compeletion-time-bar-chart {
    position: absolute !important;
    left: 5px;
    width: 70% !important;
  }
  .avg-compeletion-time svg {
    width: 90vw !important;
    height: 200px !important;
  }
  .pie-chart-div .recharts-wrapper {
    width: 100% !important;
  }
  /* .avg-compeletion-time.recharts-wrapper {
    width: auto !important;
    height: 41vh !important;
  } */
  .pie-chart-div {
    margin-top: -30px;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
  .pie-chart-label {
    margin-top: -50px;
  }
  .issue-type-row-div {
    grid-template-columns: 1fr;
  }
  .avg-completion-time-label {
    /* margin-top: -90px; */
  }

  .coustom_bar_chart {
    grid-template-columns: 0.7fr 1fr;
  }

  .coustom_bar_chart_y_axis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
