/* .App {
  text-align: center;
  overflow-x: hidden;
} */

:root {
  /* background-color: #f3f7fd; Previous*/
  --body_background: #f7f7f9;
  --body_background_white: #ffffff;
}

body {
  background-color: var(--body_background);
}

@font-face {
  font-family: "Open-Sans-regular";
  /* src: url("./assets/styles/fonts/static/OpenSans-Regular.ttf")
    format("truetype"); */
  src: url("./assets/styles/fonts2/Inter/static/Inter-Regular.ttf")
    format("truetype");
  /* font-weight: 500; */
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-semi-bold";
  /* src: url("./assets/styles/fonts/static/OpenSans-SemiBold.ttf")
    format("truetype"); */
  src: url("./assets/styles/fonts2/Inter/static/Inter-SemiBold.ttf")
    format("truetype");
  /* font-weight: 500; */
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-Light";
  /* src: url("./assets/styles/fonts/static/OpenSans-Light.ttf") format("truetype"); */
  src: url("./assets/styles/fonts2/Inter/static/Inter-Light.ttf")
    format("truetype");
  /* font-weight: 500; */
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-Medium";
  /* src: url("./assets/styles/fonts/static/OpenSans-Medium.ttf")
    format("truetype"); */
  src: url("./assets/styles/fonts2/Inter/static/Inter-Medium.ttf")
    format("truetype");
  /* font-weight: 500; */
  font-display: swap;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;

  font-family: "Open-Sans-regular", sans-serif;
  /* font-family: "Inter", Arial, sans-serif; */
  /* font-weight: 400; */
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.body {
  /* background-color: #f3f7fd !important; */
  background-color: var(--body_background) !important;
  font-size: 14px;
  color: #f3f7fd !important;
  padding: 0;
  line-height: 1.5;
  font-family: "Open Sans";
  margin: 0;
}

.container {
  max-width: 1800px;
  /* padding-left: 0px;
  padding-right: 0px; */
}

.main-container {
  display: flex;
  flex-direction: column;
  /* background-color: #f3f7fd; */
  background-color: var(--body_background);
}

.navbar-main-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 80 !important;
  /* background-color: #f3f7fd !important; */
  background-color: var(--body_background_white) !important;
  color: #ffffff;
}

.navbar-main-div-z-index {
  z-index: 92;
}

.main-inner-container {
  /* due to nav bar */
  /* margin-top: 100px; */
  /* margin-top: 64px; */
}

.navbar {
  box-shadow: none;
}

.container-fluid {
  padding: 0;
}

#navbarNav .nav-link-li {
  color: #ffffff;
}

#navbarNav .nav-link-li:hover {
  font-weight: 500;
}

.dropdown-div .nav-link-dropdown-li {
  align-self: center;
  display: flex;
  color: #006cfac0;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "Open-Sans-Light";
  font-weight: 900;
  text-transform: capitalize;
  position: relative;
  padding: 5px 0px;
  margin: -5px;
}
.nebulaa_icon {
  width: auto;
  height: 40px;
}

.navbar-collapse-content {
  transition: max-height 2s ease-in-out;
  max-height: 0;
  justify-content: end;
}
.navbar-collapse-content.show {
  max-height: 500px; /* Adjust the maximum height as needed */
}
.logout-btn {
  border-radius: 8px;
  line-height: 1.572;
  padding: 0.2rem 1rem;
  margin-right: 2px;
  border-color: transparent;
  /* box-shadow: 0 2px 2px 0 rgba(106, 130, 135, 0.22); */
  box-shadow: 0px 1px 2px 1px rgba(106, 130, 135, 0.22);
  padding: 2px 15px;
  color: #006efa;
  /* new */

  width: 100%;
  font-size: 0.85rem;
}

.logout-btn:hover {
  /* background-color: #fff1f1; */
  /* box-shadow: 0px 0px 1px 4px #FD0408; */
  color: rgb(255, 77, 73);
  font-weight: 800;
}

.dropdown-ticket-menu-ul {
  position: absolute !important;
  /* top: 10px !important; */
  left: -40px !important;
  /* z-index: 100 !important; */
}

/* session-expired-div */

/* back-to-top-arrow-button */
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.session-expired-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* background-color: transparent ; */
  height: 100vh;
  width: 100vw;
}

.session-expired-div::before {
  content: "";
  background-color: #000000;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  font-family: "Open-Sans-regular", sens-serif;
}

.session-expired-div div {
  box-shadow: 0px 0px 1px 0px gray;
  padding: 20px;
  border-radius: 8px;
  z-index: 100;
  /* background-color: #f3f7fd; */
  background-color: var(--body_background);

  display: flex;
  flex-direction: column;
  gap: 10px;
}
.session-expired-div div:hover {
  box-shadow: 0px 0px 2px 2px gray;
}
.session-expired-div h3 {
  color: rgba(76, 78, 100, 0.67);
  font-size: 20px;
}

.session-expired-div p {
  color: rgba(76, 78, 100, 0.87);
  font-size: 12px;
  font-family: "Open-Sans-Medium";
}

/* spinner */

.showSpinner {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f3f7fd; */
  background-color: var(--body_background);
  z-index: 2;
}

.ticket-form {
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 110px;
  border: 1px solid white;
  background-color: rgb(255, 255, 255);
  color: rgba(76, 78, 100, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  box-shadow: rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;
  background-image: none;
  overflow: auto;
}

.navbar-brand {
  font-weight: bold;
}

.navbar-nav,
.nav-link:hover {
  color: rgb(0, 0, 0);
}

.middleComponent {
  margin: 30px;
}

.headings {
  /* font-family: "Inter", sans-serif; */
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: #9495a2;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  /* color: rgba(76, 78, 100, 0.87); */
}

.machine_btns {
  font-family: "Open-Sans-semi-bold", sans-serif;
  border: 1px solid rgb(240, 239, 239);
  border-radius: 5px;
  margin: 0;
  background-color: rgb(240, 239, 239);
  color: rgba(76, 78, 100, 0.9);
  font-size: 13px;
  font-weight: 600;
  max-width: 270px;
  padding: 2px 10px;
  white-space: nowrap;
}

.inner-content {
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: #9495a2;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.98px;
}

.error_description,
.generated_by,
.created_date,
.error_type {
  font-family: "Open-Sans-Light", sans-serif;
  font-weight: 600;
  color: rgba(76, 78, 100, 0.87);
  display: -webkit-box;
  font-size: 13px;
  line-height: 16px;
  margin: 2px 0px;
  /* margin-right: 15px; */
  max-height: 32px;
  overflow: hidden;
  text-transform: capitalize;
}

.created_date span {
  font-family: "Open-Sans-Light", sans-serif;
  font-weight: 600;
  display: -webkit-box;
  flex-wrap: nowrap;
  text-wrap: nowrap;
}
.created_date label {
  color: rgba(76, 78, 100, 0.6);
  text-wrap: nowrap;
  font-size: 12px;
  font-weight: 500;
  flex-wrap: nowrap;
}

.error_type {
  color: rgba(76, 78, 100, 0.6);
  font-size: 13px;
  /* margin: 5px 0px; */
  /* padding-bottom: 0px; */
}
.error_type p {
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.error_description {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card {
  width: 100%;
  /* border: 1px solid white; */
  background-color: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  box-shadow: rgb(76 78 100 / 32%) 0px 2px 5px 0px;
  /* overflow: auto;   */
  padding: 3px;
}

.card:hover {
  cursor: pointer;
  box-shadow: rgb(76 78 100 / 32%) 0px 2px 10px 0px;
}

.ticket-icon {
  /* height: 12%;
  width: 12%; */
  width: 30px;
  height: 30px;
  opacity: 0.8;
  margin-right: 5px;
  margin-bottom: 3px;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(250, 250, 250);
  padding: 2px;
  border-radius: 50%;
}

.ticketid {
  /* color: rgb(89, 82, 82); */
  font-family: "Open-Sans-Medium", sans-serif;
  color: rgba(76, 78, 100, 0.9);
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  left: 16px;
  margin-bottom: 6px;
  margin-right: 15px;
  margin-top: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  top: 14px;
  white-space: nowrap;
  width: 100%;
}

.imported_img {
  /* background-color: white; */
  opacity: 0.5;
  /* font-weight: 500; */
  width: 16px;
  height: 16px;
}

.underline1 {
  border-bottom: 1px solid rgb(240, 241, 241);
}

/* .individualdatamodal {
  margin-top: ;
} */
.edit-button {
  position: absolute;
  bottom: 0;
  margin-right: 12px;
  margin-bottom: 12px;
  right: 0;
  border: 1px solid #28a745;
  border-radius: 15px;
  background-color: #28a745;
  color: #fff;
  padding: 2px 12px;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  /* color: #fff;  */
  background-color: #59ae6d;
  /* padding: 2px 12px;  */
}

.bottom-content {
  /* padding-bottom: 20px; */
  opacity: 0.79;
  width: 60%;
  /* cursor: pointer; */
}

.more-details-class span {
  font-family: "Open-Sans-Medium-Italic", sans-serif;
  font-size: 13px;
  /* font-weight: 500; */
  color: rgba(76, 78, 100, 0.87);
}

.bottom-content,
.more-details-class span:hover {
  color: rgb(48, 48, 219);
}

.bottom-content .rounded-pill {
  /* color: #4e84e99a !important;
  background-color: #9fe5e97e !important; */
  font-family: "Open-Sans-Medium";
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  white-space: nowrap;
  font-size: 13px;
  padding: 5px 14px;
}

.bottom-content .rounded-pill:hover {
  box-shadow: 0px 0px 2px 0px gray;
}

.delete-ticket-btn-border {
  margin-top: 5px !important;
  border-radius: 8px;
  padding: 8px 10px;
  box-shadow: rgba(73, 73, 73, 0.15) 1px 6px 15px 2px;
}

/* delete-ticket-btn */
.delete-ticket-btn,
.edit-ticket-btn {
  font-size: 15px;
  padding: 4px 0px;
  /* margin: 0px; */
  color: rgba(109, 111, 131, 0.87);
  font-weight: 600;
  font-style: "Open-Sans-Light";
}

.delete-ticket-btn:hover {
  color: red;
  background-color: #ffffff;
}

.edit-ticket-btn:hover {
  color: blue;
  background-color: #ffffff;
}

.delete-dropdown-visiblity {
  display: none;
  margin-top: -5px;
}
.card:hover .delete-dropdown-visiblity
/* .attachment-item:hover .delete-dropdown-visiblity  */ {
  display: block;
  /* Uncomment margin-top if required */
  /* margin-top: -5px; */
}
.card:hover .card-tid-status-heading {
  margin-right: 15px;
  /* margin-top: -5px; */
}

/* navigation bar */
.navigation {
  position: relative;
  top: 75px;
  /* left: 20px; */
  z-index: 1;
}

.navigation .nav-link {
  color: #727272;
  display: inline;
}
.navigation .nav-link:hover {
  color: #447eed;
}

.navigation-top-140 {
  top: 140px;
}

/* created date div */
.text-danger-emphasis-orange {
  color: #ff7400;
}
.text-danger-emphasis-red {
  color: #f00707;
}
.bg-danger-subtle-orange {
  background-color: #fd7d1428;
}

/* circular background to 3 dot */
.circular-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Makes the background circular */
  padding: 20px;
  width: 2px;
  height: 2px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
}

.circular-bg i {
  color: #686a72;
  font-size: 16px;
}

.circular-bg:hover {
  background-color: rgb(248, 248, 242);
  box-shadow: rgba(128, 128, 128, 0.1) 1px 1px 3px 0px;
}

@media only screen and (max-width: 1400px) {
  .navigation-top-140 {
    top: 147px;
  }
}
@media only screen and (max-width: 1200px) {
  .navigation-top-140 {
    top: 147px;
  }
  .user-details-div dd {
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 991px) {
  .navigation-top-140 {
    top: 147px;
  }
}

@media only screen and (max-width: 768px) {
  #navbarNav .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-items-ul-div {
    margin: 0px;
  }
  .dropdown-div .nav-link-dropdown-li {
    color: #ffffff;
    margin: 0px;
  }
  .dropdown-div .dropdown-item {
    background: none;
  }
  .nav-items-ul-div {
    gap: 3;
  }
  .navbar-nav .dropdown-menu {
    position: sticky !important;
    background: none;
    width: 40vw;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
  }
  .nav-items-ul-div {
    /* margin-right: 15px; */
    transition: opacity 1.5s ease;
    opacity: 0; /* Initially hide the navigation links */
    padding-bottom: 10px;
  }
  .navbar-collapse-content.show .nav-items-ul-div {
    opacity: 1; /* Show the navigation links when the navbar is fully opened */
  }
  .nav-items-ul-div {
    gap: 5px;
  }
  .logout-btn {
    margin: auto !important;
  }

  .container {
    width: 95%;
  }
  .logout-btn {
    width: inherit;
    font-size: 0.85rem;
  }
}

/* attendance loader */

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: spin 1s linear infinite;
}

@media only screen and (max-width: 576px) {
  .container-fluid {
    width: 100%;
    margin: auto;
  }

  #navbarNav .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
::-webkit-scrollbar,
.MuiDataGrid-scrollbar::-webkit-scrollbar
 {
  /* width: 0px; */
  width: 10px;
  /* height: 8px; */
  border-radius: 40px;
  background-color: #aaaaaa00; /* or add it to the track */
}

/* Track */
::-webkit-scrollbar-track, 
.MuiDataGrid-scrollbar::-webkit-scrollbar-track
 {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb,
.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb
 {
  background: rgb(211, 211, 211);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover
 {
  background: #727272;
}
