.seller_card_img {
  opacity: 0.65;
  width: 25px;
  height: 25px;
}

/* Card and Avatar Styles */
.avatar-initial {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  color: #fff;
  background-color: #f0eff0;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
  .card-body .row > div {
    margin-bottom: 1rem;
  }

  .avatar {
    margin-right: 0 !important;
  }
}
