/* margin top bottom */
.m-top-bottom {
  margin: 25px auto;
}
.svg-map {
  cursor: pointer;
  stroke: #666;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  width: 100%;
  height: auto;
  max-height: 320px;
}
/* svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
} */

.tooltip_Heading {
  font-size: 14px;
}
/* Assuming the name or id attribute for Andaman and Nicobar Islands is 'AN' */
path[data-name="Andaman and Nicobar Islands"],
path[id="an"] {
  display: none;
}

/* map desc headres data */
.map-location-desc-div {
  /* width: 100% !important; */
  width: auto;
  margin: auto !important;
  margin-left: 0;
}
.map-location-data-headers {
  font-size: 0.75rem;
}
.map-location-data-div {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.95);
  font-size: 1rem;
  padding-bottom: 20px;
}
.map-location-data-div span {
  max-width: 300px;
  /* width: 100%; */
}

.machine-unit-region-div {
  width: 100%;
  max-width: 300px;
}

/* map-div */
.map-div-div {
  /* min-width: 100px;
  max-width: 600px; */
  height: auto;
  width: 280px;
  min-width: 250px;
  position: relative;
}
.map-div-desc {
  position: absolute;
  bottom: 0px;
  right: 15px;
}
.map-div-desc-heading {
  font-size: 0.7rem;
}
.map-div-desc-heading-desc {
  font-size: 0.56rem;
  color: rgba(76, 78, 100, 0.85);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
}

@media only screen and (max-width: 1200px) {
  .map-div-div {
    /* min-width: 100px;
    max-width: 600px; */
    width: 280px;
  }
  .map-div-desc-heading-desc {
    font-size: 0.5rem;
  }
  .svg-map {
    max-height: 500px;
  }
}
@media only screen and (max-width: 768px) {
  /* .map-location-data {
    font-size: 0.75rem;
  }
  .map-location-data-headers {
    font-size: 0.85rem;
  }
  .map-div-div {
    min-width: 250px;
  }
  .map-div-desc {
    font-size: 5px;
  }
  .range_Desp {
    font-size: 0.495rem;
  }
  .map-div-desc-heading {
    font-size: 0.7rem !important;
  }
  .map-location-desc-div {
    min-width: 250px;
    padding: 5px;
  } */
}

@media only screen and (max-width: 576px) {
  /* .map-location-desc-div {
    min-width: 200px;
    padding: 5px 0px;
  } */

  .map-location-data-div {
    font-size: 1rem;
  }

  .map-div-div {
    width: 80px;
  }

  .map-div-desc-heading {
    font-size: 0.5rem;
  }
  .map-div-desc-heading-desc {
    font-size: 0.4rem;
  }

  .machine-unit-region-div {
    max-width: 100%;
  }
}
