.forgot_modal {
  padding: 2rem;
}

.otp div div input {
  width: 2rem !important;

  margin-left: 5px !important ;
  margin-right: 5px !important ;
}

.update_password_button {
  /* background-color: rgb(102, 108, 255)!important;*/
  background-color: #1976d2 !important;
  color: rgb(255, 255, 255) !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  line-height: 1.734 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.4rem !important;
  box-shadow: rgba(76, 78, 100, 0.42) 0rem 0.25rem 0.5rem -0.25rem !important;
  border: 0 !important;
  outline: none !important;
}

.update_password_button:hover {
  background-color: rgb(85, 91, 252);
}

.forgot_modal .css-1idz92c-MuiCircularProgress-svg {
  height: 16px;
  width: 16px;
  color: #ffff;
}

.forgot_modal .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  position: unset !important;
  left: 200px !important;
}

.error_forg_msg {
  color: #d32f2f;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

/* Fullscreen for small devices */
@media (max-width: 599px) {
  .ReactModal__Content {
    width: 90% !important;
  }
}

/* Fixed width for larger screens */
/* @media (min-width: 600px) {
 .forgot_modal_container .ReactModal__Content {
    width: 420px !important;
    height: auto !important; 
    max-height: 100vh; 
  }
} */

/* Fixed width for larger screens */
@media (max-width: 380px) {
  .otp div div input {
    width: 1.6rem !important;
    height: 1.8rem !important;
    margin-left: 3px !important ;
    margin-right: 3px !important ;
  }

  .forgot_password_buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .mobile_margin {
    margin-top: 10px !important;
  }
}
