.denominator {
  font-size: 0.8rem;
  color: rgba(76, 78, 100, 0.6) !important;
  font-family: "Open-Sans-Medium";
  font-weight: 500;
  text-wrap: nowrap;
}

.numerator {
  color: #4c4e64e6 !important;
  font-family: "Open-Sans-Medium";

  font-weight: 600;
  /* font-size: 1.65rem; */
  text-wrap: nowrap;
}

.seperator_line {
  border-left: 1px solid #0097d1;
  height: 28px;
  background-color: #0097d1;
  border-radius: 2px;
}

.group_button {
  /* border: 1px solid #0097d1; */
  /* box-shadow: 0px 0px 1px 1px #0097d1; */
  border-radius: 9px;
  display: flex;
  align-items: center;
}

.bg_group_buttons_sep {
  background-color: #ffffff;
  align-items: center;
  display: flex;
  height: 100%;
}

.modal-90w {
  width: 90vw;
  max-width: 1700px;
  border-radius: 20px;
}

/* crop-bar-graph-desc */
.crop-bar-graph-desc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  width: 100%;
}

/* location page css */

/* region card */

/* region card sub-heading */

/* location  modal */

/* .location_sub_headings {
  font-size: 0.85rem;
  margin: 0px;

} */

.location_modal_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.05) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal_location {
  /* background-color: white; */
  width: 500px;
  height: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}

.cards_ro {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
}

.cards_machine {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 0px 12px;
}
.region-img {
  position: relative;
  width: auto;
  height: 220px;
  border-radius: 6px;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}
.region-img:hover .background-location-img {
  transform: scale(1.1);
}

.region-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(90, 89, 89);
  opacity: 0.2;
  z-index: 10;
}

.background-location-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area */
  position: relative;
  /* z-index: 0; Ensure the image is below the pseudo-element */
  transition: transform 1s ease-in-out;
}

.location_moredetails_btn {
  border: none;
  font-size: 0.72rem;
  background-color: white;
  /* font-weight: 600; */
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid #666bff39;
}

.location_moredetails_btn:hover {
  background-color: #666bff39;
  color: #666bff !important;
}

.location_headings {
  font-family: "Open-Sans-Medium";
  /* color: rgba(76, 78, 100, 0.87); */
  /* text-wrap: wrap; */
  line-height: 1.6;
  font-weight: 500;
  font-size: 0.82rem;
  letter-spacing: 0.15px;
}

.location_headings_sub {
  font-weight: 600;
  font-size: 0.82rem;
  /* margin-top: 10px; */
  /* margin-bottom: -5px; */
  /* color: rgba(76, 78, 100, 0.99); */
}
.location_nums {
  font-weight: 600;
  font-size: 1.05rem;
  /* margin: ; */
  /* color: rgba(76, 78, 100, 0.99); */
}

/* .location_icon_background {
  border-radius: 15%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location_icon_img {
  width: 20px;
} */
/* css for district cards */
/* .location_icon_background_dist {
  border-radius: 15%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.location_icon_img_dist {
  width: 16px;
}

.location_headings_dist {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.87);
  text-wrap: wrap;
  line-height: 1.6;
  font-weight: 500;
  font-size: 0.73rem;
  letter-spacing: 0.15px;
}

.location_headings_sub_dist {
  font-weight: 600;
  font-size: 0.73rem;

  color: rgba(76, 78, 100, 0.99);
}
.location_nums_dist {
  font-weight: 600;
  font-size: 0.78rem;
} */

@media only screen and (max-width: 576px) {
  .modal-dialog {
    margin-right: auto;
    margin-left: auto;
  }
  .modal-90w {
    width: 95vw;
    max-width: 95vw;
  }
}

@media only screen and (max-width: 576px) {
  /* .cards_ro {
    grid-template-columns: 1fr !important;
  }

  .location_headings {
    font-size: 0.66rem !important;
  }

  .location_nums {
    font-size: 0.86rem;
  }

  .location_headings_sub {
    font-size: 0.66rem;
  }
  .region-img {
    height: 190px;
  }

  .location_icon_background {
    height: 30px;
    width: 30px;
  }
  .location_icon_img {
    width: 15px;
  } */
}

/* finall media querrries */
@media only screen and (min-width: 577px) and (max-width: 1050px) {
  /* .cards_ro {
    grid-template-columns: 1fr 1fr !important;
  }

  .location_headings {
    font-size: 0.7rem !important;
  }

  .location_nums {
    font-size: 0.9rem;
  }

  .location_headings_sub {
    font-size: 0.7rem;
  }
  .region-img {
    height: 200px;
  }

  .location_icon_background {
    height: 32px;
    width: 32px;
  }
  .location_icon_img {
    width: 16.5px;
  }
  .location_moredetails_btn {
    font-size: 0.68rem;
  }

  */
}
@media only screen and (min-width: 1051px) and (max-width: 1800px) {
  /* .cards_ro {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .location_headings {
    font-size: 0.75rem !important;
  }

  .location_nums {
    font-size: 0.95rem;
  }

  .location_headings_sub {
    font-size: 0.75rem;
  }
  .region-img {
    height: 200px;
  }

  .location_icon_background {
    height: 32px;
    width: 32px;
  }
  .location_icon_img {
    width: 15px;
  } */
}

@media only screen and (max-width: 768px) {
  .cards_ro {
    grid-template-columns: 1fr;
  }
}

/* Between 768px and 1200px: 2 columns */
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .cards_ro {
    grid-template-columns: 1fr 1fr;
  }
}

/* Between 1200px and 1600px: 3 columns */
@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .cards_ro {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
