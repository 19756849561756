/* whole page background */
.user-dashboard-container {
  background-color: var(--body_background);
  /* padding-top: 30px; */
}

/* greerting div */
.greeting-div {
  font-family: "Open-Sans-Light";
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: rgba(76, 78, 100, 0.55);
  margin-bottom: 5px;
}

.greeting-div h2 {
  font-family: "Open-Sans-semi-bold";
  font-size: 35px;
  font-weight: 800;
  color: rgba(76, 78, 100, 0.75);
}

/* all the continers css */
.card-Div {
  border-radius: 10px;
  min-height: 100px;
  text-transform: capitalize;
  background-color: #ffffff;
  padding: 10px;
  /* max-height: fit-content; */
  max-height: 100%;
  margin: 4px 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.card-Div:hover {
  /* box-shadow: 0px 0px 3px 0px gray; */
}

/* new card Div style  */
.card-Div-1 {
  border-radius: 10px;
  min-height: 100px;
  text-transform: capitalize;
  background-color: #ffffff;
  padding: 10px;
  /* max-height: fit-content; */
  max-height: 100%;
  /* margin: 4px 0px !important; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

/* containers icons & colors */
.user-dashboard-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.bg-red {
  background-color: rgba(240, 68, 56, 0.85);
}
.bg-green {
  background-color: rgb(16, 185, 129, 0.85);
}
.bg-blue {
  background-color: rgb(99, 102, 241, 0.85);
}
.bg-orange {
  background-color: rgb(247, 144, 9, 0.85);
}
.bg-sky-blue {
  background-color: rgb(6, 174, 212, 0.85);
}
.bg-gray {
  background-color: gray;
}
.bg-profile {
  background-color: rgb(102, 108, 255, 0.85);
}

/* card-div Heading Css */
.card-Div-heading {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.75);
  font-weight: 500;
  font-size: 1.1rem;
  text-wrap: nowrap;
}

/* resolved container css */

.resolved-div,
.pending-div {
  font-style: "Open-Sans-OpenSans-Condensed-Bold";
  padding: 8px 3px;
  padding-bottom: 5px;
  color: rgba(76, 78, 100, 0.87);
  border-top: 1px solid rgba(128, 128, 128, 0.137);
}

.resolved-div div,
.pending-div div {
  font-family: "Open-Sans-semi-bold";
  color: rgba(76, 78, 100, 0.85);
  font-size: 1rem;
  /* font-weight: 500; */
}

.resolved-div div span,
.pending-div div span {
  color: rgba(76, 78, 100, 0.6);
  font-size: 1.05rem;
  font-weight: 500;
}

.resolved-div p,
.pending-div p {
  text-align: end;
  font-size: 0.9rem;
  color: rgba(76, 78, 100, 0.5);
  font-weight: 400;
  margin-bottom: 0px;
  display: inline-block;
}

.resolved-heading-div {
  text-wrap: nowrap;
}
.resolved-heading-div span {
  padding-left: 5px;
}

/* user-details div css */
.user-details-div {
  padding: 10px 10px;
}
.user-details-div span {
  font-family: "Open-Sans-Light";
  font-weight: 600;
  color: rgba(76, 78, 100, 0.6);
  margin-bottom: 5px;
  padding-left: 12px;
  display: inline-block;
}

.user-details-div dt {
  font-family: "Open-Sans-semi-bold";
  font-weight: 600;
  font-size: 14px;
  text-wrap: nowrap;
  color: rgba(76, 78, 100, 0.6);
  align-self: flex-end;
}
.user-details-div dl {
  margin: 0px;
  margin-top: 8px;
}

.user-details-div dd {
  align-self: center;
  font-family: "Open-Sans-regular";
  font-weight: 400;
  color: rgba(76, 78, 100, 0.6);
  margin-bottom: 0px;
  text-align: center;
  padding-left: 30px;
  text-wrap: nowrap;
}

/* Events */
.event-container-main-div {
  padding: 10px;
}
.event-container-main-div span {
  font-family: "Open-Sans-Light";
  font-weight: 600;
  color: rgba(76, 78, 100, 0.6);
  /* padding-left: 15px; */
  margin-left: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.event-container {
  min-height: fit-content;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 0px;
}
.event-container div {
  padding: 5px 10px;
  margin: 10px 5px;
}
.event-details-success,
.event-details-primary,
.event-details-warning {
  /* background-color: #f3f7fd7e; */
  /* color: rgba(102, 108, 255, 0.89); */
  font-family: "Open-Sans-regular";
  font-size: 0.9rem;
}

.event-details-success {
  background-color: #dcf1e452;
}
.event-details-primary {
  background-color: #4f85e90f;
}
.event-details-warning {
  background-color: #fbf1dd52;
}
.event-details-success:hover,
.event-details-warning:hover,
.event-details-primary:hover {
  box-shadow: 0px 0px 2px 0px gray;
}
.event-details-success p,
.event-details-warning p,
.event-details-primary p {
  color: rgba(76, 78, 100, 0.6);
  font-size: 0.8rem;
  font-weight: 500;
}

.event-container::-webkit-scrollbar {
  /* width: 0px; */
  position: relative;
  width: 5px;
  height: 8px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
  /* or add it to the track */
}

/* Track */
.event-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

/* Handle */
.event-container::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

/* Handle on hover */
.event-container::-webkit-scrollbar-thumb:hover {
  background: #929292;
}
