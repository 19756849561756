.error-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 79;
    background: #f3f7fd;
    top: 0px;
  }
  