/* main card styling */
.visit-overview {
  display: grid;
  grid-template-columns: 10fr 4fr;
  column-gap: 15px;
  row-gap: 15px;
}

.basic-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 20px;
}

.right-basic-info {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;
  row-gap: 15px;
}

/* basic info inside modal content styling  */
.label-style {
  color: rgba(114, 114, 124, 0.8);
  font-size: 14px;
  margin-bottom: 0px;
}

.below-content-style {
  color: rgba(76, 78, 100, 1);
  font-size: 16px;
  margin-bottom: 0px;
}

.full-width {
  grid-column: 1 / -1; /* Start at the first column and go to the last column */
}

.hoverText {
  color: #666bff;
  font-family: "Open Sans Light", sans-serif;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  padding: 3px 15px;
  border-radius: 6px;
  box-sizing: border-box; /* Ensures padding doesn't affect overall size */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hoverText:hover {
  color: #4349f5;
  background-color: #c9cbfa39;
}

/* edit status styling  */
.edit-button-style {
  display: flex;
  margin: auto;
  text-transform: capitalize !important;
  font-size: 16px;
  padding: 5px 12px;
  border-radius: 6px;
  border: 2px solid rgba(74, 122, 243, 0.9) !important;
  color: rgba(74, 122, 243, 0.9);
  font-weight: 600;
}

.edit-button-style:hover {
  /* border: 2px solid rgb(12, 12, 236); */
  color: white !important;
  background-color: rgba(74, 122, 243, 0.9);
}

/* travel page styling  */
.accordian-border-style {
  border-radius: 12px !important;
  box-shadow: rgb(76 78 100 / 24%) 0px 0px 15px 0px !important;
  padding: 8px 5px !important;
}
.accordian-details1,
.accordian-details2 {
  margin: 10px;
  padding: 10px;
  row-gap: 10px;
  column-gap: 20px;
}
.accordian-details1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.accordian-details2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

@media screen and (max-width: 1450px) {
  .basic-info {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1200px) {
  .visit-overview {
    grid-template-columns: 5fr 3fr;
  }
  .basic-info {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 930px) {
  .visit-overview {
    grid-template-columns: 3fr 3fr;
  }
  .basic-info {
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .visit-overview {
    grid-template-columns: 1fr;
  }
  .right-basic-info {
    grid-template-columns: 1fr;
  }
  .accordian-details1 {
    grid-template-columns: 1fr;
  }
  .accordian-details2 {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 500px) {
  .basic-info {
    grid-template-columns: 1fr;
    row-gap: 15px;
  }
  .edit-button-style {
    width: 100%;
    justify-content: center;
  }
}
