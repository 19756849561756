.cards-parent1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  /* margin: 20px 20px; */
  margin-top: 20px;
}

.fci-icon-width1 {
  width: 150px;
  height: auto;
}

.card-firstPage {
  background-color: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  box-shadow: rgb(76 78 100 / 32%) 0px 2px 5px 0px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
}

.img-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px;
  margin-right: 15px;
}

.text-content-client {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 10px;
  padding-bottom: 10px;
}

.more-details {
  /* width: 1%; */
  /* flex-grow: 1; */
  /* margin-top: 10px; */
  top: 15px;
  right: 15px;
  position: absolute;
}

.main-client-title-heading {
  font-size: 25px;
  margin: 0px;
  font-weight: 500;
  padding-top: 0px;
}

.main-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
}

.card-firstPage:hover {
  /* cursor: pointer; */
  box-shadow: rgb(76 78 100 / 32%) 0px 2px 10px 0px;
}

/* Media Queries */

@media only screen and (max-width: 1400px) {
  .card-firstPage {
    flex-direction: column;
  }

  .img-div,
  .text-content-client {
    width: 100%;
  }
  .img-div {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .cards-parent1 {
    grid-template-columns: 1fr;
  }

  .card-firstPage {
    flex-direction: row;
  }

  .img-div {
    width: 30%;
  }

  .text-content-client {
    width: 70%;
  }
}

@media only screen and (max-width: 700px) {
  .card-firstPage {
    flex-direction: column;
    /* padding: 20px 15px 10px 15px; */
    /* margin: 10px 10px; */
  }

  .img-div,
  .text-content-client {
    width: 100%;
  }

  .main-stats {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .main-client-title-heading {
    font-size: 1.15rem;
  }
  .img-div {
    margin-bottom: 20px;
  }
}
