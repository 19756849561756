:root {
  --primary: #f7f7f9;
  --secondary: #666bff;
  --green: #82ca9d;
  --red: #ff4d49d9;
  --navBorderBottom: #4c4e6422;
  --taxtHeadingLight: #4c4e6499;
  --taxtHeading: #4c4e64e6;
  --tableRowHeading: #4c4e64de;
  --iconBackgroundLightBlue: #9da0f739;
  --iconBackgroundBlue: #9c9ffc39;
  --iconBackgroundWhite: #ffffffe6;
  --iconBackgroundgreen: #82ca9d39;
  --iconBackgroundred: #ff4d4939;
  --textWhiteColor: #ffffff;
  --textDescBackgroundRegionDiv: #5b61e5;
  --locationTextHeading: #4c4e64fc;
}

/* Styling for the overlay */
.ReactModal__Overlay {
  top: 0% !important;
  padding: 0;
  /* z-index: 100 !important; */
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/* Styling for the modal content */
.ReactModal__Content {
  overflow-x: hidden !important;
  padding: 0 !important;
  border-radius: 12px !important;
}

.ModalHeader {
  margin: 10px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
}

.ModalHeader .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.end-sections-header {
  display: flex;
  gap: 10px;
}

.reload-section {
  font-size: 30px;
  margin-top: 8px;
  cursor: pointer;
}

/* date picker css */
.date-Picker {
  margin: 0 !important;
  padding: 0 !important;
}

.css-4jnixx-MuiStack-root {
  /* width: 33%; */
  margin: 0px 0px;
}

/* .nav-tabs .nav-link.active{
  text-decoration: none;
} */

.general .nav-link.active {
  border: none;
  text-decoration: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none;
  text-decoration: none;
}

/* 
.activeTab:hover{
  border: none;
  text-decoration: none;
} */

.ModalHeader .nav-tabs::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
}

.ModalHeader .nav-tabs::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

.ModalHeader .nav-tabs::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

.ModalHeader .nav-tabs::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

.ModalHeader .nav-item {
  text-wrap: nowrap;
}

.showSpinnerTicketModal {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  z-index: 5;
  margin-top: 60px;
  overflow: hidden;
}

/* .crossBtn {
  color: black !important;
  margin-top: 12px;
  font-size: 20px;
} */

.general {
  text-transform: capitalize !important;
  font-size: 15px !important;
  padding: 10px;
  text-decoration: none;
  border: none;
}

.general:hover {
  text-decoration: none;
}

/* .addAttachment {
  text-transform: capitalize !important;
  background-color: rgb(215, 215, 215);
}*/

.show-attachments-content {
  margin: 12px;
  width: auto;
  border: 12px;
  padding: 10px;
  box-shadow: rgba(76 78 100 / 24%) 0px 0px 4px 0px;
}

/* .addAttachment:hover {
  color: white;
  background-color: rgba(34, 66, 171, 0.75);
}  */

.create-ticket-btn {
  text-transform: capitalize !important;
}

.resolved-btn {
  font-family: "Open-Sans-semi-bold", sans-serif;
  /* border: 1px solid rgb(240, 239, 239); */
  border-radius: 5px;
  margin: 0;
  /* background-color: rgba(242, 206, 206, 0.6); */
  color: white;
  font-size: 13px;
  font-weight: 600;
  max-width: 270px;
  padding: 5px 14px;
  white-space: nowrap;
}

.resolved_details_border {
  /* border: 1px solid rgb(45, 209, 16); */
  box-shadow: rgba(76 78 100 / 37%) 0px 0px 4px 0px;
  padding: 8px 8px 12px 8px;
  /* margin: 0px 5px; */
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 5px;
}

.resolved_details {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  column-gap: 12px;
  /* grid-gap: 10px; */
}

.css-4ldyx2 .MuiTextField-root {
  margin-left: 0 !important;
}

.resolved-btn:hover {
  cursor: none;
}

.cardContent {
  margin: auto;
  border-top: 1px solid rgb(199, 199, 199);
  min-height: 90%;
  /* padding: 25px 5px 5px 5px; */
}

.refresh_icon {
  /* display: flex; */
  /* flex-direction: row-reverse; */
  position: absolute;
  z-index: 100;
  right: 0;
  padding-right: 30px;
  padding-top: 10px;
}

.refresh_icon:hover {
  background-color: blue;
}

.col-style {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  box-shadow: rgb(76 78 100 / 24%) 0px 0px 3px 0px;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 5px;
  min-width: 48%;
  max-width: fit-content;
}

.modal-col-style {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 5px;
  box-shadow: rgb(76 78 100 / 24%) 0px 0px 3px 0px;
  /* margin-bottom: 20px; */
  /* min-width: 18%; */
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* overflow-x: scroll; */
  /* padding: 10px 5px 20px 30px; */
  padding: 10px 5px;
  /* margin: auto; */
  /* max-width: -moz-fit-content; */
  min-width: 47%;
  max-width: fit-content;
}

.col-style::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
}

.col-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

.col-style::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

.col-style::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

.modal-col-style::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
}

.modal-col-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

.modal-col-style::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

.modal-col-style::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

.scanid_title {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.7);
  font-size: 16px;
  /* font-weight: 500; */
  flex-grow: 1;
  text-wrap: wrap;
}

/* general content page styling */
.generalContent .form-label {
  color: rgba(76, 78, 100, 0.6);
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.editDescription .generalContent .labels {
  color: #78797a;
}

.generalContent .form-control {
  padding: 8.5px 12px 3.68px 12px;
  color: rgb(85, 84, 84);
}

.heading-parameters {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.7);
}

.Parameters {
  color: rgba(76, 78, 99, 0.85);
  font-family: "Open-Sans-semi-bold";
}

.manual_res {
  color: rgba(76, 78, 99, 0.75);
}

.content {
  display: flex;
  flex-direction: column;
}

/* pull Data */
.pull-data-find-div p {
  font-family: "Open-Sans-regular", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(76, 78, 100, 0.8);
  /* word-wrap: break-word; */
  text-wrap: nowrap;
  overflow-x: auto;
}

.pull-data-find-div figcaption {
  font-size: 0.6rem;
}

/* attachment page styling */

.showAttachmentsBox {
  /* margin-left: 200px; */
  width: 95%;
  margin: 0px auto;
  margin-bottom: 20px;
  padding: 20px;
  /* border: 1px solid rgb(244, 244, 244);
  box-shadow: 1px 1px 1px 0px rgb(255, 255, 255); */
  background-color: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  box-shadow: rgb(76 78 100 / 32%) 0px 2px 4px 0px;
}

.pending-attachments {
}

.pending-attachments:hover {
  color: red;
}

.add-icon-hover:hover {
  color: #5a5c5e !important;
}

.resolved-attachments:hover {
}

.resolved-attachments:hover {
  color: green;
}

.attachments-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.delete_attachments_icon {
  padding-left: 5px;
  cursor: pointer;
  user-select: none;
  color: blue;
}

.delete_attachments_icon:hover {
  cursor: pointer;
  color: grey;
}

.edit_attachments_box {
  margin-left: 30px;
  margin-right: 30px;
}

.add-attachment-text {
  font-size: 16px;
}

.add-attachment-icon {
  font-size: 14px;
}

/* attachment page styling */

/* data variation styling */

.edit-machine-detail-page {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.edit-machine-detail-page .accordion-body {
  padding: 0;
}

.accordion-item {
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px 0px;
}

.machine-scan-details {
  font-family: "Open-Sans-semi-bold";
  color: rgba(76, 78, 100, 0.7);
}

.add-machine-details-button {
  margin-bottom: 10px;
  padding: 0;
  /* display: flex;
  flex-direction: row-reverse; */
  /* width: 100%; */
}

.add-machine-details-button .add-variation-btn {
  /* width: 100%; */
}

/* to wrap large texts  */
.text-wrapper-class {
  inline-size: 150px;
  overflow-wrap: anywhere;
}

.data-variation {
  /* border: 1px solid rgb(214, 213, 213); */
  border-radius: 5px;
  box-shadow: rgba(20, 20, 23, 0.24) 0px 0px 4px 0px;
  margin: 5px 0px;
  padding: 12px;
}

.header-section-data-variation {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.header-section-scan-details {
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.data-variation-header-text {
  font-size: 16px;
}

.scan-detail-header-text {
  font-size: 16px;
}

.add-variation-btn:hover {
  background-color: blue;
  color: white;
  /* color: #3d3d3d; */
}

.data-variation-elements-container {
  /* border: 1px solid rgb(117, 142, 164); */
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  padding: 0;
}

.serial-keys-container {
  /* color: rgba(76, 78, 100, 0.7); */
  box-shadow: rgb(76 78 100 / 32%) 1px 0px 5px 0px;
  border-radius: 8px;
  margin: 10px 8px 20px 8px;
  padding: 15px;
}

.data-variation-elements {
  /* border: 1px solid rgb(214, 213, 213); */
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: rgb(76 78 100 / 24%) 0px 0px 3px 0px;
  /* margin: 10px 0px; */
  padding: 8px 10px;
}

.scan-detail {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: rgba(20, 20, 23, 0.24) 0px 0px 4px 0px;
  margin: 5px 0px 15px 0px;
  padding: 12px 10px 12px;
  gap: 20px;
}

.modal-container {
  /* z-index: 1050 !important; */
}

.modal-container .modal-footer {
  position: inherit;
}

.crop-details-container {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.crop-details-container::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
}

.crop-details-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

.crop-details-container::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

.crop-details-container::-webkit-scrollbar-thumb:hover {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

.edit-data-variation,
.edit-scan-details {
  border-radius: 8px;
  /* box-shadow: rgba(20, 20, 23, 0.24) 0px 0px 5px 2px; */
  margin: 18px 0px;
  padding: 12px;
}

.edit-data-variation-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.show-data-variation-chosen-container {
  border: 1px solid red;
}

.status-pull-data,
.status-no-pull-data {
  width: 24%;
  text-align: center;
  padding: 2px 7px;
  border-radius: 5px;

  margin-left: 5px;
  margin-bottom: 10px;
}

.status-pull-data {
  color: #68c00e;
  background-color: #e8fadd;
}

.status-no-pull-data {
  /* padding: 2px 7px;
  border-radius: 5px;
  color: white; */
  /* background-color: rgb(226, 84, 84); */
  color: #f00707;
  background-color: #fae4e8;
}

.pull-data-container {
  border: 12px;
  width: 18%;
  box-shadow: rgba(20, 20, 23, 0.24) 0px 0px 3px 0px;
  /* display: flex; */
  /* flex-direction: row-reverse; */
}

.scanid-title-modal {
  width: 75%;
}

.btn-delete-scan-detail {
  padding: 4px 14px !important;
  height: max-content;
}

.btn-delete-scan-detail:hover {
  background-color: rgb(220, 91, 91);
  color: white;
}

.cross-btn-data-variation {
  cursor: pointer;
  font-size: 20px;
}

.cross-btn-data-variation:hover {
  color: red;
}

/* data variation styling */

/* timeline page styling */

/* .css-v6mo1y-MuiTimelineDot-root{
  background-color: rgba(219, 203, 25, 0.8)!important;
} */

.pending-color {
  background-color: rgb(239, 210, 210);
  color: red;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid red;
}

.ongoing-color {
  background-color: rgb(221, 221, 236);
  color: blue;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid blue;
}

.validation-color {
  background-color: rgb(215, 177, 232);
  color: darkviolet;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid darkviolet;
}

.resolved-color {
  background-color: rgb(186, 235, 186);
  color: green;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid green;
}

.edit_basic_details .MuiCircularProgress-svg,
.css-1idz92c-MuiCircularProgress-svg {
  height: 16px !important;
}

/* .timeline-page .css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root {
  font-family: "Open-Sans-regular" !important;
  color: rgba(146, 146, 146, 0.88) !important;
  padding: 6px 8px !important;
  font-size: 14px !important;
}

.timeline-page .css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  font-family: "Open-Sans-regular" !important;
  padding: 6px 10px !important;
  color: rgba(76, 78, 100, 0.88) !important;
  font-size: 16px !important;
} */

/* timeline page styling */

/* comments page styling */
.comment-section .form-label {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.78);
}

.create-comment-btn {
  text-transform: capitalize;
  font-size: 15px;
  padding: 6px 18px;
}

.create-comment-btn:hover {
  background-color: green;
  color: white;
}

.show-comments {
  font-family: "Open-Sans-Medium";
  margin-top: 15px;
  margin-left: 20px;
  padding: 15px;
  background-color: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 5px;
  box-shadow: rgba(71, 73, 89, 0.32) 0px 0px 4px 0px;
  max-height: 450px;
  overflow-y: auto;
}

.previous-comments-header {
  font-size: 14px;
  text-align: left;
  /* margin-left: 12px; */
  margin-bottom: 5px;
  color: rgba(76, 78, 100, 0.7);
  font-weight: 700;
  border-radius: 5px;
}

.previous-comments-header-text {
  font-size: 16px;
  margin: 0 !important;
}

.previous-comments {
  /* background-color: #fafafa; */
}

.commented-by {
  font-size: 14px;
  color: rgba(76, 78, 100, 0.98);
}

.comment-text {
  font-size: 15px;
  color: rgba(76, 78, 100, 0.7);
}

.comment-date {
  display: flex;
  flex-direction: row-reverse;
}

.comment-time-span {
  /* font-family: "Open-Sans-Medium"; */
  color: rgba(76, 78, 100, 0.55);
  margin-left: 5px;
  font-size: 12px;
}

/* comments page styling */

/* data-pull-box part styling */
.data-pull-box {
  border-radius: 12px;
  /* box-shadow: rgb(76 78 100 / 24%) 0px 0px 15px 0px; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 20px;
}

.show-pull-data {
  border: 1px solid rgb(214, 213, 213);
  border-radius: 8px;
  box-shadow: rgb(76 78 100 / 24%) 0px 0px 3px 0px;
  padding: 12px;
}

/* data-pull-box part styling */

/* textinput field styling */

.general_page_main_details {
  margin: 0px 30px;
  /* margin-right: 30px; */
}

.edit-container {
  border: 1px solid rgb(214, 213, 213);
  border-radius: 8px;
  box-shadow: rgb(76 78 100 / 24%) 0px 0px 3px 0px;
  margin: 18px 0px;
  padding: 12px;
}

.edit_basic_details {
  padding: 20px 0px;
  /* display: block;
  float: left; */
}

.mark_resolved_details_btn {
  margin-top: 10px;
  margin-left: 0px;
}

.combined_buttons {
  display: flex;
  gap: 15px;
}

.edit_basic_details_button:hover {
  background-color: rgba(173, 216, 230, 0.6);
}

.markResolved_button {
  background-color: white !important;
  color: rgba(25, 87, 245, 0.9);
  border: 2px solid rgba(25, 87, 245, 0.9) !important;
}

.markResolved_button:hover {
  color: white !important;
  background-color: rgba(25, 87, 245, 0.9) !important;
}

.primary_button {
  color: #666bff;
  border: 2px solid #666bff !important;
}

.primary_button:hover {
  color: white !important;
  background-color: #666bff !important;
}

.more_details_hover:hover {
  color: white !important;
  background-color: rgba(25, 87, 245, 0.9) !important;
}

.add-pull-data-button:hover {
  color: white;
  background-color: rgba(25, 87, 245, 0.9) !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Open-Sans-Medium" !important;
  color: rgba(76, 78, 100, 0.87) !important;
  min-width: fit-content !important;
  max-width: 100% !important;
  padding: 13px 8px 6px 10px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Open-Sans-Medium" !important;
  color: rgba(76, 78, 100, 0.87) !important;
  /* min-width: fit-content !important; */
  /* padding: 13px !important; */
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Open-Sans-Medium" !important;
  color: rgba(76, 78, 100, 0.87) !important;
  min-width: fit-content !important;
  padding: 13px !important;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: revert-layer;
}

.basic_details {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 20px;
}

.basic_details_elements {
  /* margin-left: 12px; */
  min-width: 23%;
  max-width: 23%;
}

.basic_details_elements_label {
  font-size: 13px;
  color: rgba(76, 78, 100, 0.7);
  text-transform: capitalize;
}

.basic_details_elements_content {
  font-size: medium;
}

/* tag options attchment section part  */
.tag-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tag-option-button button {
  background-color: #f7f7f9;
  font-size: 16px;
  font-family: "Open-Sans-Medium";
  padding: 5px 25px;
  color: rgba(76, 78, 100, 0.9);
  text-transform: capitalize;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tag-option-button button:focus {
  outline: none;
  border: none;
}

.tag-option-button button.active {
  color: white;
  background-color: #666bff;
}

.tag-option-button button:hover {
  color: #667efd;
  background-color: var(--iconBackgroundBlue);
}

/* style to increase size */
.increase-style {
  transition: transform 0.3s ease, color 0.3s ease;
}

.increase-style:hover {
  transform: scale(1.12); /* Increase size by 20% */
  color: #4a7dff; /* Optional: Change color for hover effect */
}

.increase-style:active {
  transform: scale(1.1); /* Slightly smaller scale on click */
  color: #3a5fff; /* Optional: Change color for active click */
}

/* Prevent hover styles on active buttons */
.tag-option-button button.active:hover {
  color: white;
  background-color: #666bff;
}

.tag-option-button .icon {
  font-size: 1.2em; /* Adjust icon size */
  margin-right: 10px;
}

.tag-option-button .label {
  font-size: 1em;
}

/* Visit page Stlying */
.custom_visit_style {
  padding: 18px 4px !important;
}

.visitId {
  margin-right: 5px;
  /* box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); */
  border-radius: 5px;
  background-color: rgba(216, 216, 216, 0.9);
  color: rgba(0, 0, 0, 0.9);
  padding: 1px 10px;
}

.grid_support_person_style {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

/* 
.reset-button {
  background-color: grey;
  color: white;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  font-size: medium;
  padding: 6px 15px;
  position: absolute;
  left: 10px;
  margin-top: 55px;
}

.reset-button:hover {
  color: white;
  background-color: rgb(109, 108, 108);
} */

/* textinput field styling */

@media only screen and (max-width: 1797px) {
  /* .col-style {
    width: 29%;
  } */

  .scan-detail {
    gap: 20px;
  }
}

@media only screen and (max-width: 1400px) {
  /* .col-style {
    min-width: 35%;
  } */

  /* .scan-detail {
    gap: 40px;
  } */
}

@media only screen and (max-width: 1360px) {
  /* .col-style {
    min-width: 29%;
  } */

  /* .scan-detail {
    gap: 40px;
  } */
  .resolved_details {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
}

@media only screen and (max-width: 1180px) {
  .col-style {
    min-width: 46%;
  }

  .scan-detail {
    gap: 35px;
  }

  .basic_details_elements {
    min-width: 31%;
  }
  .mark_resolved_details_btn {
    margin-top: 0px;
    margin-left: 7px;
  }
}

@media only screen and (max-width: 1030px) {
  /* .col-style {
    min-width: 46%;
    padding: 10px 20px 20px 10px;
  } */

  .modal-col-style {
    min-width: 100%;
  }

  .scan-detail {
    gap: 20px;
    /* margin: 0; */
  }

  .general_page_main_details {
    margin-left: 20px;
    /* margin-right: 25px; */
  }

  .showSpinnerTicketModal {
    height: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .css-4jnixx-MuiStack-root {
    width: 100%;
    margin: 0px 5px;
  }
  .col-style {
    min-width: 98%;
    padding: 10px 20px 20px 10px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 875px) {
  .basic_details_elements {
    min-width: 45%;
  }
}

@media only screen and (max-width: 710px) {
  .scan-detail {
    /* gap: 5px; */
  }
}

@media only screen and (max-width: 600px) {
  .basic_details_elements {
    min-width: 100%;
  }
  .basic_details {
    gap: 13px;
  }
}

@media only screen and (max-width: 520px) {
  /* .resolved_details_border {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
  } */

  .markResolved_button,
  .resolved-btn {
    width: 100%;
  }

  .resolved-btn {
    max-width: 100%;
  }

  .combined_buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  /* .resolved_details_border {
    padding: 15px;
  } */

  .status-pull-data,
  .status-no-pull-data {
    width: 50%;
  }

  .add-machine-details-button .add-variation-btn {
    /* width: 100%; */
  }

  .scan-detail {
    /* gap: 5px; */
    /* margin-left: 20px; */
  }
}

/* styling scroll bar */
/* width */
.pull-data-find-div p::-webkit-scrollbar,
.pull-data-table-div::-webkit-scrollbar {
  /* width: 0px; */
  width: 5px;
  height: 8px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
  /* or add it to the track */
}

/* Track */
.pull-data-find-div p::-webkit-scrollbar-track,
.pull-data-table-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

/* Handle */
.pull-data-find-div p::-webkit-scrollbar-thumb,
.pull-data-table-div::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

/* Handle on hover */
.pull-data-find-div p::-webkit-scrollbar-thumb:hover,
.pull-data-table-div::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

.MuiTablePagination-root p {
  margin-bottom: 0px !important;
}

/* lot general content */

/* @media only screen and (min-width: 1200px) and (max-width: 1315px) {
  .lot_general_desp {
    gap: 25px;
  }
} */

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #666bff; /* Replace with your desired color */
  color: #666bff; /* Active tab text color */
  font-weight: bold; /* Optional for better visibility */
}
