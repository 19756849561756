.forgot_container_main {
  min-height: 80vh;
}

.forgot_container {
  /* min-height: 360px; */
  /* min-width: 400px;  */

  background-color: rgb(255, 255, 255);
  color: rgba(76, 78, 100, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;

  padding: 2.3rem 1.9rem 2rem;
}

.forgot_img img {
  /* margin-bottom: 30px;
 */

  width: 136px;
}

.forgot_title {
  font-weight: 600;
}

.forgot_des {
  font-size: 0.8rem;

  line-height: 1.429;
  letter-spacing: 0.15px;
  /* font-family: "Open"; */

  font-weight: 400;

  color: rgba(76, 78, 100, 0.6);
}

.forgot_btn {
  /* background-color: rgb(102, 108, 255)!important; */
  background-color: #1976d2 !important;
  color: rgb(255, 255, 255) !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  padding: 0.8rem 1.625rem !important;
  border-radius: 0.5rem !important;
  box-shadow: rgba(76, 78, 100, 0.42) 0rem 0.25rem 0.5rem -0.25rem !important;
  border: 0 !important;
  outline: none !important;
  width: 100%;
}

.back_to_login {
  color: rgb(102, 108, 255);
}

.forgot_btn:hover {
  background-color: rgb(97, 101, 228) !important;
}

.forgot_container .css-1idz92c-MuiCircularProgress-svg {
  height: 16px;
  width: 16px;
  color: #ffff;
}

.forgot_container .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  position: unset !important;
  left: 190px !important;
}

@media (min-width: 600px) {
  .forgot_container {
    /* max-height: 360px; */
    max-width: 400px;
  }
}
