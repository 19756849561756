.user-profile-main-img-div {
  position: relative;
  /* overflow: hidden;  */
  line-height: 0.17rem;
  margin-bottom: -40px;
}
.user-profile-main-img-div img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.user-profile-main-img-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  /* z-index: 1; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.56;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
