body {
  font-family: "Open-Sans-regular", sans-serif;
  background-color:  var(--body_background);
}

.main_container {
  margin-top: 50px;

  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin: 0rem 0rem 0.375rem;
  /* font-family: 'Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'; */
  line-height: 1.334;
  color: rgba(76, 78, 100, 0.87);
  font-weight: 600;
  letter-spacing: 0.01rem;
}

.desp {
  margin: 0px;
  line-height: 1.429;
  letter-spacing: 0.15px;
  font-family: 'Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"';
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(76, 78, 100, 0.6);
}

/* Login form*/

/* floating label for forms*/
.login_form_container {
  /* background-color: rgb(255, 255, 255); */
  color: rgba(76, 78, 100, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;
  background-image: none;
  overflow: hidden;
  padding: 1.75rem;
  background-color: #ffff;
}

.form_float {
  position: relative;
}
.form_float input {
  background: transparent;
  padding: 0.8rem 0.875rem;
  outline: none !important;
}

.labels {
  position: absolute;
  top: 1.3rem;
  left: 1rem;
  padding: 0rem 0.125rem;
  pointer-events: none;
  border-radius: 1.25rem;
  transition: top 0.5s;
  text-transform: capitalize;
}

.form_float input:focus ~ label {
  top: -0.225rem;
  background-color: white;
  font-size: 0.8125rem;
  padding: 0rem 0.3125rem;
}

.form_float input:-webkit-autofill ~ label {
  top: -0.225rem;
  background-color: white;
  font-size: 0.8125rem;
  padding: 0rem 0.3125rem;
}

.float {
  top: -0.225rem;
  background-color: white;
  font-size: 0.8125rem;
  padding: 0rem 0.3125rem;
}

/* Login button*/

.login_button {
  /* background-color: rgb(102, 108, 255)!important; */
  background-color: #1976d2 !important;
  color: rgb(255, 255, 255) !important;
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  line-height: 1.734 !important;
  padding: 0.8rem 1.625rem !important;
  border-radius: 0.5rem !important;
  box-shadow: rgba(76, 78, 100, 0.42) 0rem 0.25rem 0.5rem -0.25rem !important;
  border: 0 !important;
  outline: none !important;
  width: 100%;
}

.login_button:hover {
  background-color: rgb(85, 91, 252);
}
/* Passowrd icon*/

.input-icon-container {
  /* position: relative; */
  display: flex;
  align-items: center;
}

.form-control.input-with-icon {
  padding-right: 1.875rem;
}

.icon-inside-input {
  position: absolute;
  right: 0.625rem;
  top: -0.75rem;
  bottom: 0;
  height: 1rem;
  margin: auto;
  cursor: pointer;
}
.space_login {
  margin-right: 0.5rem !important;
}

.login_form_container .css-1idz92c-MuiCircularProgress-svg {
  height: 16px;
  width: 16px;
  color: #ffff;
}

.login_form_container .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  position: unset !important;
  left: 190px !important;
}
.form_float input:-webkit-autofill ~ label {
  top: -0.225rem;
  background-color: white;
  font-size: 0.8125rem;
  padding: 0rem 0.3125rem;
}

.while_submitting {
  opacity: 0.5;
}

@media (max-width: 375px) {
  .custom-media {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 420px) {
  .createnew_newtoplat {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
