.MuiOutlinedInput-notchedOutline {
  border-width: 0.2px !important;
  border-color: rgba(76, 78, 100, 0.3) !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #1566c1 !important;
}
.MuiStepLabel-labelContainer span {
  font-size: 18px;
  margin-top: 5px !important;
}

.raise_display_width {
  min-width: 110px;
  color: #888a98;
}

.raise_display_details {
  color: #3b4056;

  width: 100%;
}

/* .css-1y8r37a-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  padding: 10px 4px 2.5px 8px !important;
} */

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.prev_details_cards:hover {
  box-shadow: 0 0 3px 0 gray !important;
}

.selected_prev_details {
  /* box-shadow: 0 0 3px 0 #82ca9d !important; */
  /* border: 2px solid #82ca9d !important; */
  box-shadow: #82ca9d 0px 0px 1px 2px !important;
}

.prev_data_cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

@media only screen and (max-width: 1200px) {
  .prev_data_cards {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .prev_data_cards {
    grid-template-columns: 1fr;
  }
}

/* adding loat details form */

.dropDown_btns {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.lot_btn_edit:hover {
  color: #2d97d4 !important;
}

.lot_btn_delete:hover {
  color: red !important;
}

.accordion-item_lotdetails {
  margin-bottom: 15px !important;
}
