.head-institution-dropdown-detils {
  width: auto;
  height: 15rem;
  overflow-y: auto;
}

.cards-parent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  /* margin-top: 50px; */
  /* justify-content: space-between; */
}

.search-icon-bg {
  color: #ababab;
  background-color: rgb(246, 246, 246);
}

.all-ticket-content {
  background-color: var(--body_background);
  /* min-height: 100%; */
  height: 100%;
  /* margin-top: 90px; */
  /* padding-top: 35px; */
  padding-top: 75px;
  position: relative;
}

.m-top-0 {
  margin-top: 0px;
}
.p-top-0 {
  padding-top: 0px !important;
}

.all-ticket-content-blur:before {
  background: #000000;
  content: "";
  width: 100%;
  /* height: 100vh; */
  top: -300px;
  left: 0;
  bottom: -550px;
  position: absolute;
  opacity: 0.7;
  z-index: 50;
}

.dropdown-button-container {
  background: #f1f1f1;
  /* border-bottom: 1px solid #d8dde6; */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  padding-top: 10px;
  position: fixed;
  top: 77px;
  width: 100%;
  z-index: 90;
}

.show-dropdown {
  z-index: 60;
}

.filters-background {
  /* background: #ffffff; */
  z-index: 75;
  /* position: fixed; */
  /* top: 66px; */
  /* width: 100%; */
  /* left: 63px; */

  /* position: absolute; */
  top: 65px;
  /* left: 0; */
  position: fixed;
  width: -webkit-fill-available;
  padding: 5px 10px;
  border-radius: 8px;
  /* display: flex;
  flex-direction: row; */
  gap: 5px;
}
.filters-background button {
  font-family: "Open-Sans-Medium";
  padding: 6px 16px;
  text-transform: capitalize;
  border-width: thin;
}
.filters-background .container {
  overflow-x: auto;
}
.filters-background .modal-title {
  font-family: "Open-Sans-Light";
  font-size: 20px;
  font-weight: 700;
}

.filters-background .form-check-label {
  font-family: "Open-Sans-regular";
  font-size: 15px;
  color: rgba(76, 78, 100, 0.9);
}
.filters-background-left {
  /* width: 90%; */
  overflow-x: auto;
  padding-right: 15px;
  /* margin-top: 10px; */
}
.filters-background-right {
  padding-top: 4px;
  /* padding-left: 10px; */
  padding-right: 0px;
  /* padding-right: 5px; */
}

.filter-selected {
  background-color: rgba(68, 126, 237, 0.3);
}
.inside-dropdownDiv-content::-webkit-scrollbar {
  height: 5px;
}

.inside-dropdownDiv-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.inside-dropdownDiv-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.inside-dropdownDiv-content {
  overflow-x: auto;
}

.inside-dropdownDiv-content::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the background color of the scrollbar thumb */
  border-radius: 8px; /* Set the border radius to make it rounded */
}

.filter-select-dropdown {
  list-style: none;
  z-index: 1000;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 75px;
  min-width: 300px;
  max-width: 600px;
  position: fixed;
  top: 144px;
}
/* .date-select-body-div label{
  font-size: 15px;
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.75);

} */
.date-select-body-div .createdDateDiv,
.date-select-body-div .resolvedDateDiv {
  box-shadow: 0px 0px 2px 0px gray;
  border-radius: 10px;
  background-color: #f3f7fd67;
  padding: 10px 2px;
  margin: 1px 5px;
}
.date-select-body-div .created-date-heading,
.date-select-body-div .resolved-date-heading {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.75);
  text-transform: capitalize;
}

.list-style-none::-webkit-scrollbar {
  width: 7px;
}

.list-style-none::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.list-style-none::-webkit-scrollbar-track {
  background-color: transparent;
}

.list-style-none {
  list-style: none;
  overflow-y: auto;
  max-height: 60vh;
}

.more-filters {
  width: 100%;
  /* min-height: 150px; */
}

/* date filter selectors */

.date-filter-selectors-div {
  width: 95%;

  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin: 0px auto;
  margin-top: 10px;
  justify-content: center;
}
.date-filter-selectors {
  background-color: #ffffff;
  border-radius: 5px;
  width: fit-content;
  /* text-align: center; */
  /* margin: 5px auto; */
  padding: 5px;
  box-shadow: 0px 0px 1.5px 0px gray;
  font-family: "Open-Sans-regular", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(76, 78, 100, 0.78);
}

.date-filter-selectors:hover {
  box-shadow: 0px 0px 3px 0px gray;
  cursor: pointer;
}

/* .filter-select-dropdown-more{
  min-width: 450px;
  max-height: 300px;
} */

.underline {
  border-bottom: 1px solid rgb(201, 200, 200);
}

.rightDiv {
  width: 70%;
  margin: auto 2rem;
}

/* Apply the same styles to elements with a class that starts with 'selectTime' */
[class^="selectTime"] {
  padding: 8px;
  font-family: "Open-Sans-regular", sans-serif;
  border: none;
  box-shadow: 0px 0px 1px 0px rgba(128, 128, 128, 0.87);
  background-color: var(--body_background);
  font-weight: 600;
  border-radius: 5px;
  font-size: 13.6px;
  text-transform: capitalize;
}

/* Apply hover styles */
[class^="selectTime"]:hover {
  cursor: pointer;
  background-color: #5086e962;
}

.btn-outline-primary-hover {
  /* border: none; */
  background: #4f85e9;
  color: #fff;
}
.btn-outline-primary-hover:hover {
  background: #4f85e9b9;
  color: #fff;
}
/* .btn-outline-primary {
  border-color: #0d6efd;
  border: 1px solid rgba(68, 126, 237, 0.5);
  box-shadow: 0px 0px 2px 0px rgba(68, 127, 237, 0.151);
} */
/* .btn-outline-primary-selected {
  background: rgba(68, 127, 237, 0.432);
  border: 1px solid rgba(68, 126, 237, 0.5);
} */

.apply_all_filter_btn {
  background-color: #2d97d4;
  color: #fff;
}

.filter-dropdown {
  width: 100%;
  height: 100%;
}

.underline2 {
  border: 1px solid rgb(201, 200, 200);
}

/* pagination-head-container */
.count_heading_div {
  color: #006efa;
  color: rgba(76, 78, 100, 0.9);
  font-family: "Open-Sans-Light";
  font-weight: 500;
  /* padding-right: 5px; */
}

.no-ticket-found-div {
  height: 50vh;
  width: 100%;
}
.no-ticket-found-div div {
  height: 100%;
  width: 100%;
}

.no-ticket-found-div i {
  padding: 30px;
  color: #0066ff83;
}
.no-ticket-found-div p {
  /* color: #006efa; */
  color: #666bff;
}

/* Add custom styling for pagination buttons */

.pagination-head-container {
  overflow-x: auto;
  white-space: nowrap;
  text-align: end;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 10px 0px;
}

.pagination li {
  /* margin: 0 5px; */
  color: #007bff;
}

.pagination li a {
  /* text-decoration: none;
  padding: 7px 18px;
  border: 1px solid #716e6e;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer; */

  text-decoration: none;
  padding: 6px 12px;
  border: 1px solid #d8dde6;
  /* box-shadow: 0px 0px 2px 0px #716e6e; */
  border-radius: 5px;
  /* margin: 0.91px; */
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  color: #007bff;
}

.pagination li a:hover {
  background-color: rgb(222, 218, 218);
}

.pagination li.active a {
  background-color: #007bff;
  color: #fff !important;
}

.pagination li.disabled a {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.pagination li.break {
  pointer-events: none; /* Disable pointer events */
}

/* .pagination li.break a {
  cursor: default; 
} */

.container {
  margin: auto;
}

.cross-icon {
  cursor: pointer;
}

.cross-icon:hover {
  fill: red;
}
/* all filters css */
.filters-container {
  background-color: var(--body_background);
  margin: 0px;
  padding-bottom: 10px;
  /* margin-top: 50px; */
  display: flex;
  padding: 0px;
  padding-top: 75px;
  margin: auto;
  overflow-x: auto;
}

/* .filter-type {
  margin-right: 20px;
} */

.chips-wrap {
  display: flex;
  flex-wrap: nowrap;
  /* padding: 0px 5px; */
  /* border: 2px solid green; */
  font-size: 1rem;
  /* gap: 10px; */
}

.chips {
  /* margin-right: 10px; */
  padding: 1px 5px;
  margin: 2px 5px;
  box-shadow: 0px 0px 2px 0px gray;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  overflow: visible;
  border-radius: 7px;
  background-color: #ffffff;
  font-size: 13px;
}

.chips a {
  margin: 0px;
  padding: 0px;
}

.inline-heading {
  padding: 0px 4px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.icon-cross {
  margin-left: 5px;
  color: red;
}

.inline-heading p {
  margin: 0px;
  padding: 0px;
  color: #0066ff;
  font-style: italic;
  font-size: 9px;
  font-weight: 500;
}
.chips a svg {
  padding: 0px;
  height: 15px;
  width: auto;
  margin: 0px 3px;
}
.chips a svg:hover {
  color: #0066ff;
  cursor: pointer;
  /* margin: 0px 5px; */
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  height: 80%;
  font-size: 12 px;
  color: #0d6efd;
}

.flex-center:hover {
  cursor: pointer;
  color: #0066ff;
}

/* delete-modal-header */
.delete-modal-header {
  padding: 8px 15px;
}

/* spinner */
.all-ticket-spinner {
  height: 93vh;
  top: 0px;
  width: 100%;

  /* height: 100vh; */
  /* position: fixed; */
  /* left: 0px; */
  /* top: 0px; */
  /* width: 100vw; */
}

/* deleting modal */
.delete-modal-body div {
  font-family: "Open-Sans-regular", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: rgba(76, 78, 100, 0.75);
}
.delete-modal-body div span {
  font-family: "Open-Sans-regular", sans-serif;
  font-weight: 600;
  font-size: 20px;
  /* color: rgba(76, 78, 100, 0.65); */
  color: rgba(255, 0, 0, 0.65);
}
.delete-modal-body p {
  font-family: "Open-Sans-regular", sans-serif;
  font-weight: 500;
  margin: 10px 0px;
  /* font-size: 20px; */
  color: rgba(76, 78, 100, 0.65);
}
.delete-modal-body span {
  font-family: "Open-Sans-regular", sans-serif;
  font-weight: 500;
  margin: 10px 0px;
  /* font-size: 20px; */
  color: rgba(76, 78, 100, 0.75);
}

/* styling scroll bar */
/* width */
.filters-background .filters-background-left::-webkit-scrollbar,
.pagination-head-container::-webkit-scrollbar,
.filters-container::-webkit-scrollbar {
  /* width: 0px; */
  width: 5px;
  height: 8px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
  /* or add it to the track */
}

/* Track */
.filters-background .filters-background-left::-webkit-scrollbar-track,
.pagination-head-container::-webkit-scrollbar-track,
.filters-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

/* Handle */
.filters-background .filters-background-left::-webkit-scrollbar-thumb,
.pagination-head-container::-webkit-scrollbar-thumb,
.filters-container::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

/* Handle on hover */
.filters-background .filters-background-left::-webkit-scrollbar-thumb:hover,
.pagination-head-container::-webkit-scrollbar-thumb:hover,
.filters-container::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

.apply-filter-btn {
  display: none;
}
/* .apply_all_filter_btn {
  position: absolute;
  top: 15.999px;
  right: 15px;
} */

.bg-white {
  background-color: #ffffff;
}

/* export all tickets */

.export_all_data_btn {
  margin-left: 5px;
}
.export_all_data_btn:hover {
  cursor: pointer;
}
/* media query for all ticket page */
@media only screen and (max-width: 1400px) {
  .apply-filter-btn {
    display: inline-block;
  }
  .container {
    max-width: 99%;
  }
  .all-ticket-content .container {
    padding: 0px;
  }
  .filter-select-dropdown {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    left: 0;
    position: fixed;
    padding: 0;
    top: 64px;
  }
  .filters-modal-footer {
    bottom: 0;
    display: block;
    margin-left: 0;
    position: fixed;
    background: #fff;
    width: 100%;
  }
  .list-style-none {
    max-height: 75vh;
    height: 75%;
  }
}

/* params filters options */
.param-filter-option {
  padding: 4px 10px;
  box-shadow: 0px 0px 2px 0px gray;
  font-size: 1rem;
  display: inline;
  border-radius: 6px;
  font-family: "Open-Sans-Medium";
  font-weight: 300;
  color: #4c4e64e6;
  cursor: pointer;
}

.param-filter-option:hover {
  box-shadow: 0px 0px 4px 0px gray;
}

.selected-param-filter-option {
  background-color: rgba(68, 126, 237, 0.3);
  color: #0d6efd;
}

@media only screen and (max-width: 1200px) {
  .margin-right-110px {
    margin-right: 110px;
  }
  .cards-parent {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  /* .filters-container {
    padding-top: 60px;
  } */
  .container {
    /* width: 100vw; */
    max-width: 100vw;
    padding: 0px;
  }
  .cards-parent {
    grid-template-columns: 1fr;
  }
  /* .filter-select-dropdown {
    top: 55px;
  } */
}

@media only screen and (max-width: 576px) {
  .pagination {
    display: inline-flex;
  }
  /* .filters-container {
    padding-top: 60px;
  } */
  /* .margin-top-95 {
    margin-top: 95px;
  } */

  .all-ticket-content {
    padding-top: 80px;
  }
  .filters-background {
    padding: 0px 0px;
  }
  .filter-select-dropdown {
    top: 54px;
  }
}
