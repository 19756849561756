.custom-dropdown-toggle::after {
  display: none !important;
}

/* date picker css */
.date_range_container {
  background-color: grey;
  width: 275px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
}

.date_range_container input {
  box-shadow: 0px 0px 1px 0px gray;
}

.date_range_container input:hover {
  cursor: pointer;
}

.date_range_button {
  border-radius: 8px;
  box-shadow: none;
  background-color: hsl(180, 53%, 59%);
  width: 50%;
  border: none;
  background: transparent !important;
  z-index: 1 !important;
}

.date_range_button {
  /* color: white; */
}

/* header content styling */
.text-content-header {
  /* text-align: center !important; */
  /* margin-left: 100px; */
}

.fci-icon-width {
  width: 60px;
}

.text-content-header {
  margin-left: 100px;
}

.client-title-heading {
  font-size: 25px;
  margin: 0px;
  padding-top: 0px;
}

.client-front-bg {
  border-radius: 8px;
  min-height: 100px;
  background-color: #ffffff;
  padding: 10px;
  margin: 4px 4px 10px 4px;
  max-height: fit-content;
  text-transform: capitalize;

  /* box-shadow: 0px 0px 3px 0px rgb(87, 87, 87); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px;
  /* background-color: rgba(255, 255, 255, 0.7); */
}

.client-info {
  font-family: "Open-Sans-regular", sans-serif;
  font-size: 16px;
  /* padding-top: 5px; */
  font-weight: 500;
  /* margin-bottom: 5px; */
  margin: 0;
  /* line-height: 22px; */
  color: rgba(76, 78, 100, 0.8);
}

.center-dot i {
  font-size: 25px;
  /* Adjust the size as needed */
  vertical-align: middle;
  line-height: 1;
  /* Ensures the icon is properly aligned */
}

.todays_date {
  /* color: #555555; */

  color: #4c4e64e6;
}

.rmdp-container {
  /* margin-top: 10px; */
  width: 100%;
  height: 100%;
}

.rmdp-input {
  width: 100%;
  /* border: none; */
  /* height: 30px !important; */
  height: 48px !important;
  padding: 27px 12px !important;
  /* border: none !important; */
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.icon-tabler-calendar-event {
  right: 12px !important;
}
.reset_save {
  width: 100px;
  border-radius: 10px;
}

.daily_date_input {
  width: 100%;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  height: 30px !important;
  padding: 4px 12px !important;
}

.date_button_container {
  background-color: #ffffff;
  position: relative;
  border-radius: 10px;
  padding: 5px 0px;
}

.animation_monthly {
  left: 0;
}

.date_button_container {
  background-color: aliceblue;
  position: relative;
  border-radius: 10px;
  padding: 5px 0px;
  /* overflow: hidden;  */
}

.animation_button {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  background-color: aqua;
  height: 100%;
  border-radius: 10px;
  transition: left 0.5s ease;
}

.animation_daily {
  left: 50%;
}

.active_btn_ {
  color: white;
}

/* background color for range */
.rmdp-range {
  /* background-color: red !important; */
}

/* background color for todays date */
.rmdp-day.rmdp-today span {
  /* background-color: green !important; */
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  /* background-color: black !important; */
  color: #fff;
}

.cancel_button {
  color: #0074d9;
  background: transparent;
  border: none;
  margin: 0px 10px 0px auto;
  display: block;
  height: 45px;
}

/* div-overview-container */
.card-div-element {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
  flex: 25%;
}

.grid-card-div-element {
  grid-gap: 15px;
  column-gap: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* margin-right: 8px !important; */
}

.half-card-element {
  grid-gap: 15px;
  column-gap: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* margin-right: 8px !important; */
}

/* .card-div-element .card-Div-heading {
  color: #ffffff ;
  font-size: 1.125rem;
} */

.card-div-element .total-count-div span {
  color: #ffffffe0;
  margin-top: 0px;
  font-size: 1.5rem;
  font-weight: 600;
}

/* rortaing text animation */

/* this is for main page headings  */
.rotatingText-adjective {
  animation-name: rotate;
  animation-duration: 0s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  50%,
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* this is for smalled headings  */
.rotatingText-adjective-small {
  animation-name: rotate-small;
  animation-duration: 0s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes rotate-small {
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  50%,
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* here animation css ends  */

.div-overview-container {
  flex: 1;
  /* width: 100% !important; */
  height: 113px;
  color: #ffffff;
  position: relative;
}

.card-div-icon-div {
  /* height: 100%; */
  position: relative;
  /* top: 0px;
  right: 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

.card-div-icon-div img {
  height: auto;
  width: 25px;
}

.card-div-icon-div div {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* bar chart hedings*/
.barChartGraphDesc {
  width: 35px;
  height: auto;
  margin: auto;
}

.bar-chart-heading,
.card-Div-heading {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.87);
  text-wrap: nowrap;
  line-height: 1.6;
  font-weight: 500;
  font-size: 1.275rem;
  letter-spacing: 0.15px;
}

.bar-chart-num {
  font-weight: 600;
  font-size: 1.225rem;
  margin-top: 10px;
  /* margin-bottom: -5px; */
  color: rgba(76, 78, 100, 0.99);
}

.region-chart-value {
  font-weight: 600;
  font-size: 2rem;
  margin-top: 10px;
  /* margin-bottom: -5px; */
  color: rgba(76, 78, 100, 0.99);
}

.barChartDiv .recharts-wrapper,
.barChartDiv .recharts-legend-wrapper {
  width: 100% !important;
}

.barChartDivDesc {
  margin: auto;
  width: auto;
  /* margin-top: 19px; */
}

.barChartDivDescVerticalSeperator {
  width: 1px;
  background-color: #4c4e6433;
  margin: auto 10px;
  height: 60px;
}

.barChartDivDescHorizontalSeperator {
  width: 85%;
  margin: auto;
  background-color: #4c4e641a;
  height: 1px;
}

.total-scans-bar-chart-main-div {
  display: flex;
  flex-direction: row;
}

.bar-chart-desc-div {
  box-shadow: none;
  width: 175px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.bar-chart-desc-div p {
  text-wrap: wrap;
  font-size: 0.88rem;
}

.last10days-desc-div {
  box-shadow: none;
  width: 175px;
  text-align: start;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.last10days-desc-div p {
  text-wrap: nowrap;
  font-size: 0.88rem;
}

.pie-chart-main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0px;
  width: 100%;
}

.pie-chart-desc-div {
  min-width: 240px;
  width: 100%;
  overflow-x: auto;
  /* flex-wrap: wrap; */
}

.pie-chart-main-div .pie-chart-div {
  margin: auto -50px !important;
}

.cropAssesmentMainDiv {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
}

/* top-10-days-div */
.top-10-days-div {
  /* min-width: 400px; */
}

/* export button  */
.export-text-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: "Open-Sans-Light";
  font-weight: 500;
  font-size: 0.8rem;
}

.export-text-div svg {
  font-size: 20px;
}

/* time */
.createdStartDate,
.createdEndDate,
.resolvedStartDate,
.resolvedEndDate {
  padding: 0px 5px;
}

.date-select-body-div {
  width: 400px;
  min-width: 100%;
}

/* MonthDailyWeeklyScans */
.MonthDailyWeeklyScans {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  /* flex-wrap: wrap; */
  gap: 20px;
  /* margin: 20px auto; */
}

/* monlthly-active-div */

.monlthly-active-div {
  font-size: 0.9rem;
  font-family: "Open-Sans-Medium";
  font-weight: 800;
  margin: 10px 0px;
}

.monlthly-active-div span {
  min-width: 50px;
  text-align: center;
  padding: 2px;
}

/* Top-div */
.average-div {
  /* margin: auto 0px; */
  /* min-width: 300px; */
}

.top-div {
  height: 90px;
  width: fit-content;
  min-width: 230px;
}

.top-div-circular-progress {
  width: 50px;
  height: 100%;
  margin: 0px;
  margin-right: 10px;
}

.top-div-circular-progress-desc {
  font-size: 0.95rem;
  font-family: "Open-Sans-Medium";
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* min-width: 200px; */
}

.top-div-circular-progress-desc p {
  font-size: 1.25rem !important;
  font-family: "Open-Sans-semi-bold" !important;
  padding: 0 !important;
  margin: 0 !important;
}

.top-div-circular-progress-desc p span {
  font-size: 0.78rem !important;
  display: block !important;
  font-weight: 700 !important;
  margin-top: -6px !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* .top-monthly-div {
  min-width: 350px;
} */

/* region Crousel */
.custom-carousel .carousel-indicators {
  /* padding: 10px !important; */
  margin-bottom: -4px !important;
}

.custom-carousel .carousel-indicators button {
  /* background-color: rgba(76, 78, 100, 0.6) !important; */
  background-color: rgb(255, 255, 255, 0.7) !important;
  border: 1px solid #fff;
  width: 9px;
  height: 9px;
  margin: 0 2px;
  border-radius: 50%;
}

.custom-carousel .carousel-indicators .active {
  /* background-color: rgba(76, 78, 100, 0.86) !important; */
  background-color: rgba(255, 255, 255) !important;
}

.new_group_button_css {
  max-width: fit-content !important;
  margin: 2px;
  margin-bottom: 4px;

  border-radius: 9px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: visible;
}

.carousel-region-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
}

.carousel-region-desc-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;
}

.carousel-region-desc-div p {
  padding: 5px;
  font-family: "Open-sans-light";
  font-weight: 700;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  align-items: center;
  margin: 15px 5px;
  max-width: 260px;
  text-wrap: wrap;
  word-wrap: break-word;
}

.carousel-region-desc-div span {
  margin-right: 10px;
  border-radius: 5px;
  min-height: 40px;
  width: 100px;
  font-size: 1.1rem;
  padding: 0px 10px;
  text-align: center;
  align-content: center;
}

/* region map*/
.map-region-main-div {
  display: grid;
  /* grid-template-columns: 0.7fr 1fr; */
  gap: 20px;
}

/* machine-unit-div */
.machine-unit-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin: -25px auto; */
}

.gap-20 {
  gap: 20px !important;
}

@media only screen and (max-width: 1700px) {
  .pie-chart-desc-div {
    width: 450px;
  }

  .pie-chart-main-div .pie-chart-div {
    margin: auto !important;
  }
}

@media only screen and (max-width: 1400px) {
  .map-region-main-div {
    grid-template-columns: 1fr !important;
  }

  .carousel-region-desc-div p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .total-scans-bar-chart-main-div {
    flex-direction: column;
  }

  .half-card-element {
    grid-template-columns: 1fr;
    /* margin-right: 8px !important; */
  }

  .barChartDivDesc {
    width: 100%;
  }

  .barChartDivDescHorizontalSeperator {
    width: 95%;
    margin: auto;
    background-color: #4c4e641a;
    height: 1px;
  }

  .cropAssesmentMainDiv {
    /* grid-template-columns: 1fr; */
  }
}

@media only screen and (max-width: 992px) {
  .fci-icon-width {
    width: 35px;
  }

  .text-content-header {
    margin-left: 60px;
  }

  .client-title-heading {
    /* font-size: 20px; */
    margin: 0px 0px 10px 0px;
  }
}

@media only screen and (max-width: 768px) {
  /* .card-div-element {
    grid-template-columns: 1fr 1fr;
  } */
  .pie-chart-main-div {
    flex-direction: column;
  }

  .carousel-region-div img {
    margin: 15px auto;
  }

  .carousel-region-desc-div p {
    font-size: 15px;
  }

  .MonthDailyWeeklyScans {
    grid-template-columns: 1fr;
  }

  .grid-card-div-element {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 576px) {
  .card-div-element {
    flex-direction: column;
  }

  .bar-chart-desc-div {
    width: 100px;
  }

  .date-select-body-div {
    width: 200px;
  }

  /* .carousel-region-div {
    flex-direction: column-reverse;
  } */
  /* .carousel-region-div img {
   margin: 12px auto;
    margin-bottom: -20px;
  }  */
  .carousel-region-desc-div {
    grid-template-columns: 1fr;
  }

  .carousel-region-desc-div p {
    font-size: 15px !important;
  }

  .machine-unit-div {
    flex-wrap: wrap-reverse;
  }

  .pie-chart-desc-div {
    min-width: 100%;
    width: 100%;
  }

  .top-div-circular-progress-desc p {
    font-size: 0.95rem !important;
  }

  .bar-chart-heading,
  .card-Div-heading {
    font-size: 1.175rem;
  }
}

@media only screen and (max-width: 475px) {
  .fci-icon-width {
    width: 25px;
  }

  .text-content-header {
    margin-left: 45px;
  }

  .client-title-heading {
    font-size: 18px;
    margin: 0px 0px 2px 0px;
  }
}

@media only screen and (max-width: 1215px) {
  .img_hiding {
    display: none;
  }

  /* .fci-icon-width {
    width: 25px;
  }

  .text-content-header {
    margin-left: 45px;
  }

  .client-title-heading {
    font-size: 18px;
    margin: 0px 0px 2px 0px;
  } */
}

@media only screen and (max-width: 345px) {
  .fci-icon-width {
    width: 23px;
  }

  .text-content-header {
    margin-left: 40px;
  }

  .client-title-heading {
    font-size: 16px;
    margin: 0px 0px 2px 0px;
    padding-top: 2px;
  }
}

/* styling scroll bar */
/* width */
.region_wise_div::-webkit-scrollbar,
.style-scrollbar::-webkit-scrollbar,
.last-10-days-desc::-webkit-scrollbar,
.css-1l8asae-MuiTableContainer-root::-webkit-scrollbar,
.css-1ajgrc7-MuiTableContainer-root::-webkit-scrollbar,
.new_group_button_css::-webkit-scrollbar {
  /* width: 0px; */
  position: relative;
  width: 5px;
  height: 8px;
  border-radius: 20px;
  background-color: #aaaaaa0a;
  /* or add it to the track */
}

/* Track */
.region_wise_div::-webkit-scrollbar-track,
.style-scrollbar::-webkit-scrollbar-track,
.last-10-days-desc::-webkit-scrollbar-track,
.css-1l8asae-MuiTableContainer-root::-webkit-scrollbar-track,
.css-1ajgrc7-MuiTableContainer-root::-webkit-scrollbar-track,
.new_group_button_css::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
}

/* Handle */
.region_wise_div::-webkit-scrollbar-thumb,
.style-scrollbar::-webkit-scrollbar-thumb,
.last-10-days-desc::-webkit-scrollbar-thumb,
.css-1l8asae-MuiTableContainer-root::-webkit-scrollbar-thumb,
.css-1ajgrc7-MuiTableContainer-root::-webkit-scrollbar-thumb,
.new_group_button_css::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 211, 0.322);
  border-radius: 10px;
}

/* Handle on hover */
.region_wise_div::-webkit-scrollbar-thumb:hover,
.style-scrollbar::-webkit-scrollbar-thumb:hover,
.last-10-days-desc::-webkit-scrollbar-thumb:hover,
.css-1l8asae-MuiTableContainer-root::-webkit-scrollbar-thumb:hover,
.css-1ajgrc7-MuiTableContainer-root::-webkit-scrollbar-thumb:hover,
.new_group_button_css::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .grid-card-div-element {
    grid-template-columns: 1fr 1fr;
  }
}

/* Between 1200px and 1600px: 3 columns */
/* @media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .grid-card-div-element {
    grid-template-columns: 1fr 1fr 1fr;
  }
} */
