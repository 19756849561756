.update_machine_location {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-bottom: 10px;
}
.add_new_machine {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
  .update_machine_location {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .update_machine_location {
    grid-template-columns: 1fr;
  }
  .add_new_machine {
    grid-template-columns: 1fr;
  }
}
