.prev_visits_container {
  box-shadow: rgb(76 78 100 / 35%) 0px 0px 3px 0px;
  padding: 20px 22px;
  /* box-shadow:     rgba(76 78 100 / 24%) 0px 0px 10px 0px; */
  margin: 8px;
  border-radius: 8px;
  width: 32%;
  font-size: 15px;
  cursor: pointer;
}

.date_details {
  font-size: 12px;
  color: rgba(76, 78, 100, 0.6);
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-left: 22px;
}
.expense {
  margin-right: 6px;
}

.edit_visit_icon img {
  width: 21px;
  position: absolute;
  top: 23px;
  right: 50px;
  opacity: 0.6;
  cursor: pointer;
}

/* .separator{
  border-right: 1px solid grey;
} */

.modal_elements .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.modal_elements
  .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 6px !important;
}

/* .main_visit_modal_container
  .css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input:disabled {
  padding: 13px;
} */

/* .main_visit_modal_container
  .visit_details_form
  .css-4jnixx-MuiStack-root
  .MuiTextField-root {
  min-width: unset !important;
 
} */
/* date */
/* .main_visit_modal_container > .MuiTextField-root {
  min-width: unset !important;
} */
/* .main_visit_modal_container .css-1pp2716-MuiStack-root > .MuiTextField-root {
  min-width: unset !important;
} */
.min_width_unset {
  min-width: unset !important;
}
.main_visit_modal_container
  .css-clttge-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 5px !important;
}

.main_visit_modal_container .visit_details_form {
  padding: 13px !important;
}

.main_visit_modal_container .visit_details_form {
  padding: 13px !important;
}

.main_visit_modal_container
  .css-clttge-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 8px !important;
}

.main_visit_modal_container .visit_details_form .css-4jnixx-MuiStack-root {
  padding: 8px !important;
  margin: 0px !important;
}

/* opacity when disbaled */
.visit_details_form .css-38raov-MuiButtonBase-root-MuiChip-root.Mui-disabled {
  opacity: 0.7 !important;
}
@media (max-width: 450px) {
  .mobile_screen_size {
    min-width: 100% !important;
  }
}

@media (max-width: 1400px) {
  .prev_visits_container {
    min-width: 48%;
  }
}

@media (max-width: 900px) {
  .prev_visits_container {
    width: 98%;
    margin: 8px 0px;
  }
}

@media (max-width: 435px) and (min-width: 250px) {
  .visit_details_small_screen {
    display: flex !important;
    flex-direction: column !important  ;
  }

  .custom_small {
    display: flex !important;
    flex-direction: column !important;
  }

  .custom_small div {
    margin-bottom: 8px !important;
  }
}
