body {
  font-family: "Open-Sans-regular", sans-serif;
  background-color:  var(--body_background);
}
.signup_button {
  /* background-color: rgb(102, 108, 255); */
  background-color: #1976d2 !important;
  color: rgb(255, 255, 255);
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.734;
  padding: 0.8rem 1.625rem;
  border-radius: 0.5rem;
  box-shadow: rgba(76, 78, 100, 0.42) 0rem 0.25rem 0.5rem -0.25rem;
  border: 0;
  outline: none;
  width: 100%;
}
.signup_form {
  padding: 1.75rem;
}

.signup_form_container {
  background-color: rgb(255, 255, 255);
  color: rgba(76, 78, 100, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;
  background-image: none;
  overflow: hidden;
}

.space_sigin {
  margin-right: 0.5rem !important;
}

/* error msg for form inputs */

.error {
  color: red;
  font-size: 0.85rem;
}

/* postion_for password span */
.error_msg_password {
  position: absolute;
  top: 3.75rem;
}

.signup_form_container .css-1idz92c-MuiCircularProgress-svg {
  height: 16px;
  width: 16px;
  color: #ffff;
}

.signup_form_container .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  position: unset !important;
  left: 190px !important;
}

.signup_form_container .css-zzms1-MuiSnackbar-root {
  top: 75px !important;
}

.while_submitting {
  opacity: 0.5;
}
@media (max-width: 590px) {
  .custom_media_small_screens {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
