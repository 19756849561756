.upper-card-basic-info {
    border-radius: 6px;
    min-height: 100px;
    background-color: #ffffff;
    box-shadow: 0px 0px 1px 0px gray;
    padding: 10px;
    margin-bottom: 10px;
    max-height: fit-content;
    text-transform: capitalize;
    box-shadow: 0px 0px 3px 0px rgb(185, 185, 185);
}

.upper-card-basic-info:hover {
}

.basic_details_elements_label_data_table {
    font-size: 12px;
    color: rgba(76, 78, 100, 0.7);
}

.basic_details_elements_data_content {
    font-size: 15px;
}

.top-ticket-sources-label-data-table {
    min-height: 40px !important;
    /* text-align: center !important; */
    font-family: "Open-Sans-Medium";
    font-size: 20px;
    font-weight: 400;
    color: rgba(76, 78, 100, 0.95);
    margin-top: 30px !important;
    background-color: rgba(234, 235, 237, 0.95);
    /* color:rgba(9,4,432,0.4); */
  }
