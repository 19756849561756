.Card-Div {
  border-radius: 6px;
  min-height: fit-content;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px 0px gray;
  padding: 10px;
  margin: 4px -4px;
  min-width: fit-content;
  max-height: fit-content;
  /* color: rgb(239, 154, 154); */
}

.Card-Div:hover {
  box-shadow: 0px 0px 3px 0px gray;
}

/*  showing total count container */
.total-count-div {
  /* background-color: #f3f7fd; */
  border-radius: 20px;
  margin: 0px;
  margin-bottom: 0px;
  padding: 0px;
  width: 36%;
  font-family: "Open-Sans-Light";
  color: rgba(76, 78, 100, 0.9);
}

/* .total-count-div {

} */

.total-count-div span {
  font-weight: 600;
  margin-bottom: -5px;
}

.total-count-div p {
  margin-bottom: 0px;
  color: rgba(76, 78, 100, 0.5);
  font-family: "Open-Sans-Light";
  font-weight: 600;
  font-size: 15px;
  text-wrap: nowrap;
  /* font-size: 16px; */
}

/* card-div Heading Css */
.total-count-div .card-Div-heading {
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.75);
  font-weight: 500;
  font-size: 0.2rem;
  text-wrap: nowrap;
}

.upper-heading-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.info-heading {
  font-size: 16px;
  color: rgba(76, 78, 100, 0.99);
}
.show-range {
  font-size: 14px;
  color: rgba(76, 78, 100, 0.7);
}

.linechart-main-icon-heading {
  font-size: large;
}

#info-icon {
  /* size: large; */
  height: 100px !important;
  color: black;
  font-weight: bold !important;
}

#button-tooltip {
  min-width: 400px !important;
}

/* .hover-container {
  display: inline-block;
  position: relative;
}

#info-text {
  position: absolute;
  top: -20px; 
  left: 25px; 
  background-color: #fff;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: none;
} */

/* #info-icon:hover + #info-text {
  display: inline-block;
} */

/* containers icons & colors */
.user-dashboard-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

/* linechart heading  */
.linechart-heading-div,
.linechart-xaxis-div {
  font-size: 20px;
  font-family: "Open-Sans-Medium";
  color: rgba(76, 78, 100, 0.75);
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
  margin: 0;
}

.linechart-xaxis-div {
  font-size: 16px;
  margin-top: 15px;
}

.issueLineChartContainer {
  width: 100%;
  overflow-x: hidden;
}

/* styling for table part in table form more detail modal  */
.moredetail-gridstyle {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px; /* Adjust gap as needed */
}

.group-buttons .css-o4iapl-MuiButtonBase-root-MuiButton-root {
  background-color: #2d97d4;
}

.moredetail-gridstyle li {
  list-style: none;
  text-transform: capitalize;
}

/* visit line charat analysis */
.bg_top_ticket_scorer {
  background-color: #fff;
  box-shadow: 0 0 1px 0 gray;
  border-radius: 6px;
  padding: 16px 18px 32px;
  margin-bottom: 3rem;
}

.card-Divs {
  border-radius: 6px;
  margin-bottom: 10px;
  max-height: fit-content;
  text-transform: capitalize;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 1px 0 gray;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  min-height: 100px;
  padding: 10px;
}

.border_cont {
  /* box-shadow: 0px 0px 1px 0px gray; */
  box-shadow: 0px 0px 3px 0px rgb(185, 185, 185);
}

.trends_subtitle {
  font-size: 1rem;
  margin-left: 8px;
  color: #909099b3;
}

.anaylatics_title {
  color: rgba(76, 78, 100, 0.75);
}

.top_visits_headings {
  font-family: "Open-Sans-Light";
  color: rgba(76, 78, 100, 0.85);
  font-weight: 700;
  font-size: 0.9rem;
  text-wrap: nowrap;
}
/* Adjusting to 2 elements in a row after 1200px */
@media (max-width: 1400px) {
  .moredetail-gridstyle {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Adjusting to 1 element in a row after 600px */
@media (max-width: 1100px) {
  .moredetail-gridstyle {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 760px) {
  .moredetail-gridstyle {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1100px) {
  .issueLineChartContainer {
    overflow-x: scroll;
    /* max-width: 98vw; */
  }
  /* Tablet view: Two cards per row */
}

@media only screen and (max-width: 656px) {
  .upper-heading-container {
    flex-direction: column;
  }
}
