.date-picker-container {
  position: relative;
  /* margin-bottom: 20px;  */
}

.floating-label {
  position: absolute;
  left: 10px;
  top: -8px;
  font-size: 12px;
  color: #999;
  transition: 0.2s ease all;
  background-color: white;
  padding: 0 4px;
  z-index: 1;
  border-radius: 4px;
}
